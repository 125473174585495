import fetchApi from 'services/api/connector';

export const fetchHosts = (ws, queryParam) => fetchApi(`ws/${ws}/hosts/filter?q=${escape(JSON.stringify(queryParam))}`);

export const getHosts = (ws, stats) => fetchApi(`ws/${ws}/hosts?stats=${stats}`);

export const createHost = (ws, data) => fetchApi(`ws/${ws}/hosts`, { method: 'POST', data });

export const updateHost = (ws, data) => fetchApi(`ws/${ws}/hosts/${data.id}`, { method: 'PATCH', data });

export const deleteHost = (ws, ids) => fetchApi(`ws/${ws}/hosts`, { method: 'DELETE', data: { ids }, version: 'v3' });

export const fetchTotalHosts = (workspace) => fetchApi(`ws/${workspace}/hosts/filter?q=${escape(JSON.stringify({ limit: 1, offset: 0, filters: [] }))}`);

export const setHostsTags = (ws, data) => fetchApi(`ws/${ws}/hosts/set_tags`, { method: 'POST', data, version: 'v3' });

export const setAllHostsTags = (ws, queryParam, data) => fetchApi(`ws/${ws}/hosts/set_tags?q=${encodeURI(JSON.stringify(queryParam))}`, { method: 'POST', data, version: 'v3' });

export const getGlobalHosts = () => fetchApi('global_hosts');

export const updateHosts = (ws, data) => fetchApi(`ws/${ws}/hosts`, { method: 'PATCH', data, version: 'v3' });

export const updateAllHosts = (ws, queryParam, data) => fetchApi(`ws/${ws}/hosts?q=${encodeURI(JSON.stringify(queryParam))}`, { method: 'PATCH', data, version: 'v3' });

export const deleteAllHosts = (ws, queryParam) => fetchApi(`ws/${ws}/hosts?q=${encodeURI(JSON.stringify(queryParam))}`, { method: 'DELETE', version: 'v3' });

export const moveAssets = (ws, data) => fetchApi(`ws/${ws}/hosts/move_assets`, { method: 'POST', data });
