import uniq from 'lodash/uniq';
import initialState from './initialState';
import * as types from './types';

const hostsGeneral = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_HOSTS_DATA_START: {
      return {
        ...state,
        isFetching: true
      };
    }
    case types.GET_HOSTS_DATA_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        hostsList: action.data,
        hostCount: action.hostCount
      };
    }
    case types.GET_HOSTS_DATA_FAILURE: {
      return {
        isFetching: false,
        error: true,
        errorMessage: action.errorMessage
      };
    }
    case types.GET_HOSTS_DATA_GROUP_BY_RESET: {
      return {
        ...state,
        groupBy: '',
        mapGroupByIndex: []
      };
    }
    case types.GET_HOSTS_DATA_GROUP_BY_EXPAND: {
      const { mapGroupByIndex } = state;
      const newMapGroupByIndex = { ...mapGroupByIndex, [action.index]: action.data };
      const hostsList = state.hostsList;
      hostsList[action.index].groupData = action.data;

      return {
        ...state,
        mapGroupByIndex: newMapGroupByIndex,
        hostsList,
        isFetching: false
      };
    }
    case types.GENERAL_HOSTS_SELECTED: {
      return {
        ...state,
        hostsSelected: uniq([...state.hostsSelected, ...action.payload]),
        hostSelectedForWorking: uniq([...state.hostsSelected, ...action.payload])
      };
    }
    case types.GENERAL_HOSTS_UNSELECTED: {
      const filterByID = (array, id) => array.filter((el) => el._id !== id);
      const newHostsSelected = action.payload.reduce((hostsSelected, hostToDelete) => filterByID(hostsSelected, hostToDelete._id), state.hostsSelected);

      return {
        ...state,
        hostsSelected: newHostsSelected
      };
    }
    case types.GENERAL_HOSTS_NEW_PIVOT: {
      return {
        ...state,
        lastSelected: action.payload
      };
    }
    case types.GENERAL_HOSTS_SELECT_ALL_HOST: {
      return {
        ...state,
        hostsSelected: [...action.hostsList]
      };
    }
    case types.GENERAL_HOSTS_UNSELECT_ALL_HOST: {
      return {
        ...state,
        hostsSelected: []
      };
    }
    case types.GENERAL_HOSTS_SHOW_DETAIL: {
      return {
        ...state,
        hostDetail: action.host
      };
    }
    case types.GENERAL_HOSTS_HIDE_DETAIL: {
      return {
        ...state,
        hostDetail: null
      };
    }
    case types.GENERAL_HOSTS_CREATE_SUCCESS: {
      const hostsList = state.hostsList;

      return {
        ...state,
        hostsList: [...action.hosts, ...hostsList]
      };
    }
    case types.GENERAL_HOSTS_CREATE_FAIL: {
      return {
        ...state,
        error: true,
        errorMessage: action.error
      };
    }
    case types.GENERAL_HOSTS_DELETE_HOSTS_START: {
      return {
        ...state,
        isFetching: true
      };
    }
    case types.GENERAL_HOSTS_DELETE_HOSTS_SUCCESS: {
      return {
        ...state,
        hostsSelected: [],
        isFetching: false
      };
    }
    case types.GENERAL_HOSTS_DELETE_HOSTS_FAIL: {
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage
      };
    }
    case types.GENERAL_HOSTS_SHOW_FILTERS: {
      return {
        ...state,
        showRightFilters: action.visible
      };
    }
    case types.GENERAL_HOSTS_CLEAR_ERROR: {
      return {
        ...state,
        error: false,
        errorMessage: ''
      };
    }
    case types.GENERAL_HOSTS_SET_BULK_UPDATE_FIELD: {
      return {
        ...state,
        bulkUpdateField: action.field,
        bulkUpdateValue: ''
      };
    }

    case types.GENERAL_HOSTS_SET_BULK_UPDATE_VALUE: {
      return {
        ...state,
        bulkUpdateValue: action.value
      };
    }

    case types.GENERAL_HOSTS_ADD_BULK_UPDATE_VALUE: {
      const bulkUpdateValue = state.bulkUpdateValue;
      bulkUpdateValue.push(action.value);

      return {
        ...state,
        bulkUpdateValue
      };
    }

    case types.GENERAL_HOSTS_REMOVE_BULK_UPDATE_VALUE: {
      let bulkUpdateValue = state.bulkUpdateValue;
      bulkUpdateValue = bulkUpdateValue.filter((element) => element !== action.value);

      return {
        ...state,
        bulkUpdateValue
      };
    }

    case types.GENERAL_HOSTS_SHOW_CONTEXT_MENU: {
      return {
        ...state,
        showContextMenu: action.show,
        contextMenuXPos: action.contextMenuXPos,
        contextMenuYPos: action.contextMenuYPos
      };
    }

    case types.GENERAL_HOSTS_REFRESH_LIST: {
      return {
        ...state,
        hostsList: action.assetsList,
        hostCount: action.assetsCount,
        hostsSelected: action.assetsSelected,
        hostDetail: action.assetDetail
      };
    }

    case types.GENERAL_HOSTS_CONFIRMATION_CHANGE_START: {
      return {
        ...state,
        isFetching: true,
        isFetchingExpandedData: true,
        error: false,
        errorMessage: ''
      };
    }

    case types.GENERAL_HOSTS_SET_BULK_UPDATE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        error: false,
        errorMessage: ''
      };
    }

    case types.GENERAL_HOSTS_BULK_UPDATE_FINISHED: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        bulkUpdateField: '',
        bulkUpdateValue: ''
      };
    }

    case types.GENERAL_HOSTS_ERROR: {
      return {
        ...state,
        error: true,
        errorMessage: action.error
      };
    }

    case types.GENERAL_HOSTS_UPDATE_HOSTS_LIST: {
      return {
        ...state,
        hostsList: action.hostsList
      };
    }

    case types.GENERAL_HOSTS_SELECT_TOTAL_ASSETS: {
      return {
        ...state,
        selectAll: true
      };
    }

    case types.GENERAL_HOSTS_UNSELECT_TOTAL_ASSETS: {
      return {
        ...state,
        selectAll: false
      };
    }

    case types.SET_ASSETS_TAGS_SUCCESS: {
      return {
        ...state,
        isFetching: false
      };
    }

    case types.SET_ASSETS_TAGS_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.error
      };
    }

    case types.GENERAL_HOSTS_RESET_STATE: {
      return {
        ...initialState,
        hostSelectedForWorking: state.hostSelectedForWorking
      };
    }

    default:
      return state;
  }
};

export default hostsGeneral;
