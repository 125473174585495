import React from 'react';
import CustomReactTable from 'Common/Components/Contextualization/CustomReactTable/styled';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash/debounce';
import { withBreakpoints } from 'react-breakpoints';
import {
  selectIsFetching, selectShowDetail, selectVulns,
  selectSelected,
  selectShowContextMenu
} from 'store/Contextualization/AssetDetail/selectors';
import { selectCustomAttributes } from 'store/CustomAttributes/selectors';
import { selectVulnsAssetFields } from 'store/Preferences/vulnsAsset/selectors';
import { setFieldWidth } from 'store/Preferences/vulnsGeneral/actions';
import { PropTypes } from 'prop-types';
import getColumns from 'Screens/Contextualization/Manage/components/ManageTable/components/Table/columns/Columns';
import { handleResizedChange, getThProps, getTrProps } from './Functions';
import { setOrderByVulnsInHost } from 'store/Host/actions';
import { selectVulnRow, showContextMenu, unSelectAll } from 'store/Contextualization/AssetDetail/actions';
import { selectRiskScoreFeatureFlag } from 'store/Faraday/selectors';
import { selectShowQuickPlanner } from 'store/Manage/selectors';

const Table = ({ currentBreakpoint }) => {
  const dispatch = useDispatch();
  const customFields = useSelector(selectCustomAttributes);
  const showVulnDetail = useSelector((state) => selectShowDetail('vulns', state));
  const vulnsSelected = useSelector((state) => selectSelected('vulns', state));
  const isFetching = useSelector((state) => selectIsFetching('vulns', state));
  const data = useSelector(selectVulns);
  const fields = useSelector(selectVulnsAssetFields);
  const isAllowedByLicense = useSelector(selectRiskScoreFeatureFlag);

  const isDisplayingContextMenu = useSelector(selectShowContextMenu);
  const noDataText = isFetching ? '' : <FormattedMessage id="workspaces.table.noData" />;
  const showQuickPlanner = useSelector(selectShowQuickPlanner);
  const columns = getColumns(true, customFields, showVulnDetail, currentBreakpoint, false, fields, isFetching, false, false, isAllowedByLicense, true, showQuickPlanner);

  const selectRowAction = (row) => dispatch(selectVulnRow({}, row));
  const unSelectAllAction = (row) => dispatch(unSelectAll('vulns'));
  const setFieldWidthAction = (field, value) => dispatch(setFieldWidth(field, value, 'manage_table'));

  const handleResizeColumn = (newResized) => handleResizedChange(newResized, fields, setFieldWidthAction);
  const showContextMenuAction = (value, xPos, yPos) => dispatch(showContextMenu(value, xPos, yPos));

  const fetchData = (tableState) => !isFetching && dispatch(setOrderByVulnsInHost(tableState.sorted));

  const mockData = [...Array(15).keys()];

  return (
    <CustomReactTable
      data={ isFetching ? mockData : data }
      columns={ columns }
      minRows={ 0 }
      manual
      onFetchData={ fetchData }
      showPagination={ false }
      getTrProps={ (_, rowInfo) => getTrProps(rowInfo, vulnsSelected, selectRowAction, unSelectAllAction, isFetching, showContextMenuAction) }
      getTheadThProps={ (_, __, column) => getThProps(column, fields, currentBreakpoint, showVulnDetail, showContextMenuAction, isDisplayingContextMenu) }
      onResizedChange={ debounce(handleResizeColumn, 200) }
      noDataText={ noDataText }
    />
  );
};

Table.propTypes = {
  currentBreakpoint: PropTypes.string.isRequired
};

export default withBreakpoints(Table);
