import filter from 'lodash/filter';
import * as types from './types';
import { uniq } from 'lodash';

export const initialState = {
  isFetching: false,
  error: false,
  errorMessage: '',
  errors: {},
  token: '',
  tokenDuration: 60,
  userAgents: [],
  cloudAgents: [],
  triggerAgent: {
    tools: [],
    workspaces: [],
    ignoreInfo: false,
    resolveHostname: false,
    hasVulnsTags: false,
    hasServicesTags: false,
    hasAssetsTags: false
  },
  selectedCloudAgent: null,
  agentTypes: {},
  draftNewAgent: {
    name: '',
    server: '',
    tools: []
  },
  agentsSelected: [],
  lastSelected: -1,
  agentCount: 0,
  showCloudAgents: false
};

function agents (state = initialState, action) {
  switch (action.type) {
    case types.AGENTS_FETCHING: {
      return {
        ...state,
        isFetching: true,
        error: false,
        errorMessage: '',
        errors: {}
      };
    }

    case types.AGENTS_GET_USER_AGENTS: {
      return {
        ...state,
        isFetching: false,
        error: false,
        userAgents: action.userAgents
      };
    }

    case types.AGENTS_GET_CLOUD_AGENTS: {
      return {
        ...state,
        isFetching: false,
        error: false,
        cloudAgents: action.cloudAgents
      };
    }

    case types.AGENTS_SELECT_CLOUD_AGENT: {
      return {
        ...state,
        selectedCloudAgent: action.cloudAgent
      };
    }

    case types.AGENTS_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.errorMessage,
        errors: action.errors
      };
    }

    case types.AGENTS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: '',
        errors: {}
      };
    }

    case types.AGENTS_GET_TOKEN: {
      return {
        ...state,
        isFetching: false,
        error: false,
        token: action.token,
        tokenExpiresIn: action.tokenExpiresIn,
        tokenDuration: action.tokenDuration
      };
    }

    case types.AGENTS_GET_TOOLS: {
      return {
        ...state,
        isFetching: false,
        error: false,
        triggerAgent: {
          ...state.triggerAgent,
          agentId: action.id,
          tools: action.tools
        }
      };
    }

    case types.AGENTS_SET_ID: {
      return {
        ...state,
        isFetching: false,
        error: false,
        triggerAgent: {
          ...state.triggerAgent,
          agentId: action.id
        }
      };
    }

    case types.AGENTS_RUN_SET_WORKSPACES: {
      return {
        ...state,
        triggerAgent: {
          ...state.triggerAgent,
          workspaces: action.workspaces
        }
      };
    }

    case types.AGENTS_RUN_SET_IGNORE_INFO: {
      return {
        ...state,
        triggerAgent: {
          ...state.triggerAgent,
          ignoreInfo: action.ignoreInfo
        }
      };
    }

    case types.AGENTS_RUN_SET_RESOLVE_HOSTNAME: {
      return {
        ...state,
        triggerAgent: {
          ...state.triggerAgent,
          resolveHostname: action.resolveHostname
        }
      };
    }

    case types.AGENTS_CREATE_WIZARD_RESET: {
      return {
        ...state,
        isFetching: false,
        error: false,
        draftNewAgent: initialState.draftNewAgent
      };
    }

    case types.AGENTS_CREATE_WIZARD_ADD_TOOL: {
      return {
        ...state,
        isFetching: false,
        error: false,
        draftNewAgent: {
          ...state.draftNewAgent,
          tools: [...state.draftNewAgent.tools, action.tool]
        }
      };
    }

    case types.AGENTS_CREATE_WIZARD_REMOVE_TOOL: {
      return {
        ...state,
        isFetching: false,
        error: false,
        draftNewAgent: {
          ...state.draftNewAgent,
          tools: filter(state.draftNewAgent.tools, (e) => (e.name !== action.tool))
        }
      };
    }

    case types.AGENTS_CREATE_WIZARD_SET_FIELDS_TOOL: {
      // se reemplaza el objeto con newitem.
      const newItem = [
        { name: action.toolName, variables: action.fields }
      ];
      const tools = state.draftNewAgent.tools.map((obj) => newItem.find((o) => o.name === obj.name) || obj); // busco el objeto y reemplazo.

      return {
        ...state,
        isFetching: false,
        error: false,
        draftNewAgent: {
          ...state.draftNewAgent,
          tools
        }
      };
    }

    case types.AGENTS_GET_MANIFEST: {
      return {
        ...state,
        agentTypes: action.data,
        isFetching: false
      };
    }

    case types.SET_NEW_AGENT: {
      return {
        ...state,
        draftNewAgent: {
          ...action.data
        }
      };
    }

    case types.RESET_NEW_AGENT: {
      return {
        ...state,
        draftNewAgent: initialState.draftNewAgent
      };
    }

    case types.AGENT_SELECTED: {
      return {
        ...state,
        agentsSelected: uniq([...state.agentsSelected, ...action.payload])
      };
    }
    case types.AGENT_UNSELECTED: {
      const filterByID = (array, id) => array.filter((el) => el.id !== id);
      const newAgentsSelected = action.payload.reduce((agentsSelected, agentToDelete) => filterByID(agentsSelected, agentToDelete.id), state.agentsSelected);

      return {
        ...state,
        agentsSelected: newAgentsSelected
      };
    }
    case types.NEW_PIVOT: {
      return {
        ...state,
        lastSelected: action.payload
      };
    }
    case types.SELECT_ALL_AGENT: {
      return {
        ...state,
        agentsSelected: [...action.agentsList]
      };
    }
    case types.UNSELECT_ALL_AGENT: {
      return {
        ...state,
        agentsSelected: []
      };
    }
    case types.TOGGLE_AGENTS_TAB: {
      return {
        ...state,
        showCloudAgents: action.payload,
        agentsSelected: []
      };
    }

    case types.AGENTS_RUN_SET_TAGS_ENTITY: {
      const tagEntity = action.entity.charAt(0).toUpperCase() + action.entity.slice(1);
      const field = `has${tagEntity}`;
      return {
        ...state,
        triggerAgent: {
          ...state.triggerAgent,
          [field]: action.value
        }
      };
    }

    case types.AGENTS_RESET_STATE: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
}

export default agents;
