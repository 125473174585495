import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  margin-top: 20px;
`;
Wrapper.displayName = 'Wrapper';

export const Row = styled.div`
  display: flex;
  align-items: center;
  & >:last-child {
    width: 325px;
  }
`;
Row.displayName = 'Row';

export const Title = styled.div`
  font-size: 14.5px;
  color: ${colors.grey17};
  margin-bottom: 19px;
  position: relative;
  &:after {
    content: '*';
    color: ${colors.redPink};
    position: absolute;
    top: 3px;
    margin-left: 3px;
  }
`;
Title.displayName = 'Title';
