import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from './styled';
import { setField } from 'store/Settings/serviceDesk/actions';
import { selectTemplateFieldValue, selectTemplateSelected } from 'store/Settings/serviceDesk/selectors';

const TextField = ({ fieldName, mandatory }) => {
  const dispatch = useDispatch();
  const title = fieldName.split('_').join(' ');
  const placeholder = 'Plain text or JSON';
  const value = useSelector((state) => selectTemplateFieldValue(state, fieldName));
  const templateSelected = useSelector(selectTemplateSelected);
  // const templates = [{ name: 'Incident Report', mandatory: { asset: 'holis.com', site: 'google.com'}}, { name: 'Incident 2', optional: { asset: 'holis.com' }}];

  const handleChange = (v) => {
    const template = templateSelected;
    if (templateSelected?.fields?.length > 0 && templateSelected.fields.find(el => Object.hasOwn(el, fieldName))) {
      if (v) {
        const fieldToUpdate = { [fieldName]: { data: { raw: v }, type: mandatory ? 'mandatory' : 'optional' }, deleted: false };
        const filteredFields = templateSelected.fields.filter(item => !Object.hasOwn(item, fieldName));
        Object.assign(template, { fields: [...filteredFields, fieldToUpdate] });
      } else {
        const fieldToUpdate = { [fieldName]: { data: { raw: v }, type: mandatory ? 'mandatory' : 'optional' }, deleted: true };
        const filteredFields = templateSelected.fields.filter(item => !Object.hasOwn(item, fieldName));
        Object.assign(template, { fields: [...filteredFields, fieldToUpdate] });
      }
    } else if (templateSelected?.fields?.length > 0 && !templateSelected.fields.find(el => Object.hasOwn(el, fieldName))) {
      const fieldToUpdate = { [fieldName]: { data: { raw: v }, type: mandatory ? 'mandatory' : 'optional' }, deleted: false };
      Object.assign(template, { fields: [...templateSelected?.fields, fieldToUpdate] });
    } else {
      const fieldToUpdate = { [fieldName]: { data: { raw: v }, type: mandatory ? 'mandatory' : 'optional' }, deleted: false };
      Object.assign(template, { fields: [fieldToUpdate] });
    }
    dispatch(setField('templateSelected', template));
  };

  return (
    <CustomInput
      value={ value }
      name={ fieldName }
      onChange={ handleChange }
      placeholder={ placeholder }
      title={ mandatory ? title : '' }
      mandatory={ mandatory }
      className={ mandatory ? '' : 'sd-optional-fields-text' }
    />
  );
};

export default TextField;
