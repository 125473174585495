import api from 'services/api';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import * as types from './types';
import { selectApiToken, selectIsBasicSetupConfig, selectRequestersAdded, selectSeveritiesOutgoing, selectStatusIncoming, selectStatusOutgoing, selectTemplateActive, selectTemplatesAdded, selectTemplateSelected, selectUrl } from './selectors';
import { SEND_VULN_TO_TOOL, updateActiveConfigNew } from '../settingsActions';
import { UPDATE_VULN_CLIENT_SIDE } from 'store/Manage/types';
import { VULNS_ASSET_UPDATE_VULN_CLIENT_SIDE } from 'store/Contextualization/AssetDetail/types';
import { GENERAL_MANAGE_UPDATE_VULN_CLIENT_SIDE } from 'store/ManageGeneral/types';
import { loadVulnerabilityDetail } from 'store/Manage/actions';
import { loadManageDetail } from 'store/Contextualization/AssetDetail/actions';
import { loadVulnDetail } from 'store/ManageGeneral/actions';

export const TOOL_NAME = 'mesd';

const fetchStart = () => ({ type: types.SETTINGS_SERVICE_DESK_REQUEST });

export const setField = (field, value) => ({ type: types.SETTINGS_SERVICE_DESK_SET_FIELD, field, value });
export const resetState = () => ({ type: types.SETTINGS_SERVICE_DESK_RESET });

export const error = (errorMessage) => (dispatch) => {
  dispatch({ type: types.SETTINGS_SERVICE_DESK_FAILURE, errorMessage });
};

export const getServiceDeskSettings = () => async (dispatch) => {
  dispatch({ type: types.GET_SETTINGS_TICKETING_TOOLS_SERVICE_DESK_START });
  try {
    const response = await api.settings.fetchTicketingTools('mesd');
    return dispatch({ type: types.GET_SETTINGS_TICKETING_TOOLS_SERVICE_DESK_SUCCESS, response });
  } catch (e) {
    return dispatch({ type: types.GET_SETTINGS_TICKETING_TOOLS_SERVICE_DESK_FAIL, error: true, message: 'There was an error, please try again.' });
  }
};

export const authenticateServiceDesk = () => async (dispatch, getState) => {
  dispatch(fetchStart());
  try {
    const url = selectUrl(getState());
    const api_token = selectApiToken(getState());
    const basicSetupConfigured = selectIsBasicSetupConfig(getState());
    const activeTemplate = selectTemplateActive(getState());

    const saveAuth = await api.settings.saveServiceDeskAuth(url, api_token);
    if (saveAuth) {
      const validateResponse = await api.settings.validateAuthServiceDesk();
      if (validateResponse?.result === 'SUCCESS') {
        const savedAuthConfig = await api.settings.saveServiceDeskAuthConfig(url, api_token, true);
        dispatch({ type: types.SETTINGS_SERVICE_DESK_AUTH_SUCCESS });
        if (savedAuthConfig && basicSetupConfigured && activeTemplate) {
          dispatch(updateActiveConfigNew('serviceDesk'));
        }
      }
    }
  } catch (e) {
    return dispatch({ type: types.SETTINGS_SERVICE_DESK_AUTH_FAILURE, errorMessage: get(e, 'result', 'There was an error, please try again') });
  }
};

export const getRequesters = (search, field, setDropdown) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const response = await api.settings.getSearchDataSD(search, field);
    if (response?.data) setDropdown(response.data);
    return dispatch({ type: types.SETTINGS_SERVICE_DESK_GET_REQUESTERS_SUCCESS, requesters: response.data });
  } catch (e) {
    return dispatch(error(get(e, 'result', 'There was an error, please try again')));
  }
};

export const getDataByQuery = (search, field, setDropdown) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const response = await api.settings.getSearchDataSD(search, field);
    if (response?.data) setDropdown(response.data);
    return dispatch({ type: types.SETTINGS_SERVICE_DESK_GET_SEARCH_DATA_SUCCESS, field, data: response.data });
  } catch (e) {
    return dispatch(error(get(e, 'result', 'There was an error, please try again')));
  }
};

export const getDataForDropdown = (field) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const response = await api.settings.getDropdownDataSD(field);
    return dispatch({ type: types.SETTINGS_SERVICE_DESK_GET_DROPDOWN_DATA_SUCCESS, field, data: response.data });
  } catch (e) {
    return dispatch(error(get(e, 'result', 'There was an error, please try again')));
  }
};

export const getDataForGroupedDropdown = (field) => async (dispatch) => {
  dispatch({ type: types.SETTINGS_SERVICE_DESK_GET_GROUPED_DROPDOWN_DATA_REQUEST });
  try {
    const response = await api.settings.getDropdownDataSD(field);
    const parsedResponse = Object.keys(response.data).map(category => {
      const options = response.data[category].map(subcategory => ({
        label: subcategory,
        value: {
          groupedLabel: `${category} - ${subcategory}`,
          category,
          subcategory
        }
      }));
      return {
        label: category,
        options
      };
    });

    return dispatch({ type: types.SETTINGS_SERVICE_DESK_GET_GROUPED_DROPDOWN_DATA_SUCCESS, field, data: parsedResponse });
  } catch (e) {
    return dispatch(error(get(e, 'result', 'There was an error, please try again')));
  }
};

export const getServiceDeskTemplates = () => async (dispatch) => {
  dispatch({ type: types.SETTINGS_SERVICE_DESK_TEMPLATES_REQUEST });
  try {
    const response = await api.settings.fetchTemplates('mesd');
    return dispatch({ type: types.SETTINGS_SERVICE_DESK_TEMPLATES_SUCCESS, templates: response?.data?.templates });
  } catch (e) {
    return dispatch({ type: types.SETTINGS_SERVICE_DESK_TEMPLATES_FAILURE, error: true, errorMessage: 'Faraday was not able to fetch templates.' });
  }
};

export const getOptionalFields = (id, fields) => async (dispatch, getState) => {
  try {
    const templatesAdded = selectTemplatesAdded(getState());

    const templateAdded = templatesAdded.find((t) => t.id === id);
    if (!isEmpty(templateAdded)) {
      const optionalFields = templateAdded?.fields?.filter(el => { return ((Object.values(el)[0].type === 'optional') || (Object.values(el)[1]?.type === 'optional')) && (!el.deleted); });
      const optionalFieldsParsed = optionalFields?.flatMap((item) => {
        const key = Object.keys(item).filter((k) => k !== 'deleted')?.[0];
        if (key) {
          const filtered = fields.optional.filter((element) => element.name === key);
          return filtered;
        } else return [];
      });
      dispatch(setField('optionalFieldsToAdd', optionalFieldsParsed || []));
    }
  } catch (e) {
  }
};

export const getTemplateDataById = (id, name) => async (dispatch, getState) => {
  dispatch({ type: types.SETTINGS_SERVICE_DESK_TEMPLATE_DATA_REQUEST });
  try {
    const response = await api.settings.fetchTemplateDataByIdSD(id);
    const templates = selectTemplatesAdded(getState());
    dispatch({ type: types.SETTINGS_SERVICE_DESK_TEMPLATE_DATA_SUCCESS, templateToEdit: { id, name, fields: response.data } });
    dispatch(getOptionalFields(id, response.data));
    const templateSelected = templates.find(t => t.name === name);
    const templateData = templateSelected;
    dispatch({ type: types.TEMPLATE_SELECTED_SD, templateSelected: templateData?.fields ? templateData : { ...templateData, fields: [] } || { id, name, fields: [] } });
  } catch (e) {
    dispatch(error(get(e, 'result', 'There was an error, please try again')));
  }
};

export const setRequester = (item) => (dispatch) => {
  dispatch({ type: types.SETTINGS_SERVICE_DESK_SET_REQUESTER, item });
};

export const removeTypeSearchFieldValue = (fieldname, item) => (dispatch) => {
  dispatch({ type: types.SETTINGS_SERVICE_DESK_REMOVE_VALUE_SEARCH, fieldname, item });
};

export const setSeveritiesType = (severity) => (dispatch) => {
  dispatch({ type: types.SETTINGS_SERVICE_DESK_SET_SEVERITIES_TYPE, severity });
};

export const setStatusType = (status, type) => (dispatch) => {
  if (type === 'outgoing') dispatch({ type: types.SETTINGS_SERVICE_DESK_SET_OUTGOING_STATUS, status });
  else dispatch({ type: types.SETTINGS_SERVICE_DESK_SET_INCOMING_STATUS, status });
};

export const saveBasicSetupSD = () => async (dispatch, getState) => {
  try {
    const url = selectUrl(getState());
    const api_token = selectApiToken(getState());
    const requester = selectRequestersAdded(getState());
    const severity_outgoing = selectSeveritiesOutgoing(getState());
    const status_incoming = selectStatusIncoming(getState());
    const status_outgoing = selectStatusOutgoing(getState());

    await api.settings.saveBasicSetupServiceDesk(url, api_token, requester, severity_outgoing, status_incoming, status_outgoing);
    return dispatch({ type: types.SETTINGS_SERVICE_DESK_BASIC_SETUP_SUCCESS });
  } catch (e) {
    return dispatch({ type: types.SETTINGS_SERVICE_DESK_BASIC_SETUP_FAILURE, errorMessage: (get(e, 'result', 'There was an error, please try again')) });
  }
};

export const saveTemplateConfig = () => async (dispatch, getState) => {
  try {
    const activeTemplate = selectTemplateActive(getState());
    const url = selectUrl(getState());
    const api_token = selectApiToken(getState());
    const templateSelected = selectTemplateSelected(getState());
    const templates = selectTemplatesAdded(getState());
    const filteredTemplates = templates.filter((t) => t.name !== templateSelected?.name);
    const newTemplates = [...filteredTemplates, templateSelected];
    const orderedTemplates = newTemplates.sort((a, b) => a.order - b.order);
    dispatch(setField('template_list', orderedTemplates));

    const allTemplates = selectTemplatesAdded(getState());
    const template_list = allTemplates.reduce((acc, { name, id, fields }) => {
      const key = `${name} - ${id}`;
      acc[key] = fields || [];
      return acc;
    }, {});

    const data = {
      url,
      api_token,
      template_list,
      active_template: activeTemplate || ''
    };

    const response = await api.settings.saveConfigServiceDesk(data);
    return dispatch({ type: types.SETTINGS_SERVICE_DESK_SAVE_TEMPLATES_SUCCESS, response });
  } catch (e) {
    return dispatch({ type: types.SETTINGS_SERVICE_DESK_SAVE_TEMPLATES_FAILURE, errorMessage: (get(e, 'result', 'There was an error, please try again')) });
  }
};

export const activateTemplate = (templateName) => async (dispatch, getState) => {
  try {
    const url = selectUrl(getState());
    const api_token = selectApiToken(getState());
    const response = await api.settings.activeTemplate(url, api_token, templateName);
    dispatch({ type: types.SETTINGS_SERVICE_DESK_ACTIVATE_TEMPLATE_SUCCESS, response });
  } catch (e) {
    return dispatch({ type: types.SETTINGS_SERVICE_DESK_ACTIVATE_TEMPLATE_FAILURE, errorMessage: (get(e, 'result', 'There was an error, please try again')) });
  }
};

export const deleteTemplate = (filteredTemplates, templateName) => async (dispatch, getState) => {
  try {
    const url = selectUrl(getState());
    const api_token = selectApiToken(getState());
    const activeTemplate = selectTemplateActive(getState());
    const template_list = filteredTemplates.reduce((acc, { name, id, fields }) => {
      const key = `${name} - ${id}`;
      acc[key] = fields;
      return acc;
    }, {});
    const data = {
      url,
      api_token,
      template_list,
      active_template: activeTemplate === templateName ? '' : activeTemplate
    };
    await api.settings.saveConfigServiceDesk(data);
    dispatch({ type: types.SETTINGS_SERVICE_DESK_DELETE_TEMPLATE_SUCCESS, template_list: filteredTemplates });
    if (activeTemplate === templateName) {
      dispatch(activateTemplate(''));
      dispatch(updateActiveConfigNew('serviceDesk'));
    }
  } catch (e) {
    return dispatch({ type: types.SETTINGS_SERVICE_DESK_DELETE_TEMPLATE_FAILURE, errorMessage: (get(e, 'result', 'There was an error, please try again')) });
  }
};

export function sendVulnToServiceDeskContext (vulnsSelected, vulnId, isVulnDetailOpen, entity) {
  return async (dispatch) => {
    dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: true, error: false, message: '' } });
    try {
      const data = { vulns: vulnsSelected };

      const updatedVulns = await api.settings.sendVulnToServiceDesk(data);
      updatedVulns.forEach(vuln => {
        if (entity === 'vulns') dispatch({ type: UPDATE_VULN_CLIENT_SIDE, payload: { updatedVuln: vuln, toolName: 'mesd' } });
        else if (entity === 'vulnsAssets') dispatch({ type: VULNS_ASSET_UPDATE_VULN_CLIENT_SIDE, payload: { updatedVuln: vuln, toolName: 'mesd' } });
        else dispatch({ type: GENERAL_MANAGE_UPDATE_VULN_CLIENT_SIDE, payload: { updatedVuln: vuln, toolName: 'mesd' } });
      });

      if (isVulnDetailOpen) {
        if (entity === 'vulns') dispatch(loadVulnerabilityDetail(vulnId, true));
        else if (entity === 'vulnsAssets') dispatch(loadManageDetail(vulnId));
        else dispatch(loadVulnDetail(vulnId));
      }
      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: false, message: 'Operation was executed successfully.' } });
    } catch (e) {
      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: true, message: get(e, 'message', 'There was an error, please try again.') } });
    }
  };
}

export const deauthenticated = () => async (dispatch, getState) => {
  try {
    const url = selectUrl(getState());
    const api_token = selectApiToken(getState());
    await api.settings.saveServiceDeskAuthConfig(url, api_token, false);
    dispatch({ type: types.SETTINGS_SERVICE_DESK_DEAUTHENTICATED });
    dispatch(updateActiveConfigNew('serviceDesk'));
  } catch (e) {

  }
};

export const saveTicketConfig = () => async (dispatch, getState) => {
  try {
    const url = selectUrl(getState());
    const api_token = selectApiToken(getState());
    const data = {
      url,
      api_token,
      ticket_config: {
        template: 'manage_engine_service_desk_default_template.jinja2'
      }
    };
    await api.settings.saveConfigServiceDesk(data);
  } catch (e) {
    dispatch(error(get(e, 'result', 'There was an error, please try again')));
  }
};

export const updateTemplateSelected = (templateSelected) => (dispatch, getState) => {
  const templatesAdded = selectTemplatesAdded(getState());
  dispatch({ type: types.SETTINGS_SERVICE_DESK_UPDATE_TEMPLATE_SELECTED, templateSelected, templatesAdded });
};

export const addTemplate = (templates, templateName) => async (dispatch, getState) => {
  try {
    const templatesAdded = selectTemplatesAdded(getState());
    const hasTemplatesAdded = templatesAdded?.length > 0;
    const activeTemplate = selectTemplateActive(getState());
    const url = selectUrl(getState());
    const api_token = selectApiToken(getState());
    const template_list = templates.reduce((acc, { name, id, fields }) => {
      const key = `${name} - ${id}`;
      acc[key] = fields || [];
      return acc;
    }, {});

    const data = {
      url,
      api_token,
      template_list,
      active_template: hasTemplatesAdded ? activeTemplate : templateName
    };

    const response = await api.settings.saveConfigServiceDesk(data);
    dispatch({ type: types.SETTINGS_SERVICE_DESK_SAVE_TEMPLATES_SUCCESS, response });
    if (!hasTemplatesAdded) dispatch(updateActiveConfigNew('serviceDesk'));
  } catch (e) {
    return dispatch({ type: types.SETTINGS_SERVICE_DESK_SAVE_TEMPLATES_FAILURE, errorMessage: (get(e, 'result', 'There was an error, please try again')) });
  }
};

export const resetTemplateSelected = () => ({ type: types.SETTINGS_SERVICE_DESK_RESET_TEMPLATE });
