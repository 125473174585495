import styled from 'styled-components';
import colors from 'Styles/colors';
import { FaChevronDown as ChevronDown } from 'react-icons/fa';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

Wrapper.displayName = 'SearchListWrapper';

export const InputContainer = styled.div`
  display: flex;
  position: relative;
`;

InputContainer.displayName = 'InputContainer';

export const Input = styled.input`
  width: 100%;
  height: 34px;
  padding: 0px 22px 0px 10px;
  box-shadow: inset 0 -1px 0 0 #afafaf;
  border: none;
  border-left: 12px solid ${colors.softBlue};
  background-color: ${colors.iceBlue};
  color: ${colors.grey17};
  font-size: 14.5px;

  ::placeholder {
    color: ${colors.grey17};
  }
`;

Input.displayName = 'Input';

export const List = styled.div`
  width: 100%;
  box-shadow: 0 0 10px 10px rgba(222, 222, 222, 0.16);
  border: solid 1px #e8e8e8;
  background-color: ${colors.white1};
  max-height: 138px;
  overflow: auto;
  position: absolute;
  z-index: 3;
`;

Input.displayName = 'Input';

export const Loading = styled(List)`
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
Loading.displayName = 'Loading';

export const DownArrow = styled(ChevronDown)`
  color: ${colors.blueGrey};
  size: 0.6em;
  position: absolute;
  right: 10px;
  top: 9px;
  width: 12px;
  height: 16px;
`;

DownArrow.displayName = 'DownArrow';

export const Primary = styled.span`
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.44;
  color: ${colors.dark2}
`;

Primary.displayName = 'Primary';

export const Secondary = styled.span`
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.52;
  letter-spacing: normal;
  color: ${colors.blueGrey}
`;

Secondary.displayName = 'Secondary';

export const Item = styled.span`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 10px;
  &:hover {
    background-color: ${colors.iceBlue};
  }
`;

Item.displayName = 'Item';
