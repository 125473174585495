import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import CVSS3Calculator from './components/CVSS3Calculator';
import { Title } from '../ExpandableWrapper/styled';
import {
  Wrapper, Header, Button, Arrow, ResultsWrapper,
  Results, ResultsTitle, Score, Vector, CVSS2Title,
  IconWrapper, Remove
} from './styled';
import CVSS4Calculator from './components/CVSS4Calculator';

const CVSS = ({ showRiskInfo, currentVuln, updateVuln, tempCVSS, resetCVSS, entity }) => {
  const [showCVSS3Calculator, toggleCVSS3Calculator] = useState(false);
  const [showCVSS4Calculator, toggleCVSS4Calculator] = useState(false);
  const { cvss2, cvss3, cvss4 } = currentVuln;
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));
  const dispatch = useDispatch();
  const intl = useIntl();
  const resultsText = intl.formatMessage({ id: 'manage.detail.tab.general.cvss3.results' });
  const showCvss2 = !cvss3?.vector_string && !cvss4?.vector_string;
  const showCvss3 = !cvss4?.vector_string;

  const cvss3VectorString = () => {
    if (tempCVSS) return tempCVSS.vector_string;
    else if (cvss3?.vector_string) return `${cvss3?.vector_string}`;
    else return 'CVSS:3.1/AV:_/AC:_/PR:_/UI:_/S:_/C:_/I:_/A:_';
  };

  const cvss3Score = () => {
    if (tempCVSS && tempCVSS.base_score) return `${tempCVSS.base_severity} (${tempCVSS.base_score})`;
    else if (cvss3?.base_score) return `${cvss3.base_severity} (${cvss3.base_score})`;
    else return 'N/A';
  };

  const cvss3Severity = () => {
    if (tempCVSS && tempCVSS.base_severity) return tempCVSS.base_severity;
    else if (cvss3.base_severity) return cvss3.base_severity;
    else return '';
  };

  const incompleteVector = cvss3VectorString().includes('_');

  const cvss4VectorString = () => {
    if (cvss4?.vector_string) return `${cvss4.vector_string}`;
    else return 'CVSS:4.0/AV:N/AC:L/AT:N/PR:N/UI:N/VC:H/VI:H/VA:H/SC:H/SI:H/SA:H/E:A/CR:H/IR:H/AR:H/MAC:L/MAT:P/MPR:H/MUI:N/MVC:H/MVI:H/MVA:H/MSC:H/MSI:S/MSA:S';
  };

  const cvss4Score = () => {
    if (cvss4?.base_score) return `${cvss4.base_severity} (${cvss4.base_score})`;
    else return 'N/A';
  };

  const cvss4Severity = () => {
    if (cvss4?.base_severity) return cvss4.base_severity;
    else return '';
  };

  useEffect(() => {
    toggleCVSS3Calculator(false);
    toggleCVSS4Calculator(false);
    dispatch(resetCVSS());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVuln.id]);

  const removeCVSS = (field) => {
    dispatch(updateVuln(currentVuln, field, { vector_string: '' }));
    if (field === 'cvss3') {
      dispatch(resetCVSS());
      toggleCVSS3Calculator(false);
    } else {
      toggleCVSS4Calculator(false);
    }
  };

  if (!showRiskInfo) return null;
  return (
    <Wrapper>
      <Header>
        <Title children={ intl.formatMessage({ id: 'manage.detail.tab.general.cvss4' }) } />
        { canUpdate &&
          <Button onClick={ () => toggleCVSS4Calculator(!showCVSS4Calculator) } >
            { showCVSS4Calculator ? 'Hide Calculator' : 'Show Calculator' }
            { showCVSS4Calculator
              ? <Arrow>&#9662;</Arrow>
              : <Arrow>&#9656;</Arrow>
            }
          </Button>
        }
      </Header>
      <ResultsWrapper>
        <ResultsTitle>{ resultsText }</ResultsTitle>
        <Results>
          <Score severity={ cvss4Severity() }>{ cvss4Score() }</Score>
          <Vector disable={ (!cvss4 || !cvss4?.vector_string) }>
            { cvss4VectorString() }
            { canUpdate &&
              <IconWrapper onClick={ () => removeCVSS('cvss4') } fullVector={ cvss4?.vector_string } >
                <Remove />
              </IconWrapper>
            }
          </Vector>

        </Results>
      </ResultsWrapper>
      { showCVSS4Calculator && <CVSS4Calculator cvss4Data={ cvss4 } entity={ entity } /> }
      { showCvss3 &&
        <>
          <Header>
            <Title children={ intl.formatMessage({ id: 'manage.detail.tab.general.cvss3' }) } />
            { canUpdate &&
              <Button onClick={ () => toggleCVSS3Calculator(!showCVSS3Calculator) } >
                { showCVSS3Calculator ? 'Hide Calculator' : 'Show Calculator' }
                { showCVSS3Calculator
                  ? <Arrow>&#9662;</Arrow>
                  : <Arrow>&#9656;</Arrow>
                }
              </Button>
            }
          </Header>
          { showCVSS3Calculator && <CVSS3Calculator cvssData={ cvss3 } entity={ entity } /> }
          <ResultsWrapper>
            <ResultsTitle>{ resultsText }</ResultsTitle>
            <Results>
              <Score severity={ cvss3Severity() }>{ cvss3Score() }</Score>
              <Vector disable={ (!cvss3 || !cvss3?.vector_string) && (!tempCVSS || !tempCVSS.vector_string) }>
                { cvss3VectorString() }
                { canUpdate &&
                  <IconWrapper onClick={ () => removeCVSS('cvss3') } fullVector={ !incompleteVector } >
                    <Remove />
                  </IconWrapper>
                }
              </Vector>

            </Results>
          </ResultsWrapper>
        </>
      }

      { showCvss2 &&
        <>
          <CVSS2Title children={ intl.formatMessage({ id: 'manage.detail.tab.general.cvss2' }) } />
          <ResultsWrapper>
            <ResultsTitle>{ resultsText }</ResultsTitle>
            <Results>
              <Score severity={ (cvss2.base_score && cvss2.base_severity) ? cvss2.base_severity : '' }>{ cvss2.base_score ? `${cvss2.base_severity} (${cvss2.base_score})` : 'N/A' }</Score>
              <Vector disable={ (!cvss2 || !cvss2.vector_string) } >{ cvss2?.vector_string ? `CVSS:2.0/${cvss2.vector_string}` : 'CVSS:2.0/AV:N/AC:M/Au:N/C:N/I:P/A:N' }</Vector>
            </Results>
          </ResultsWrapper>
        </>
      }
    </Wrapper>
  );
};

export default CVSS;
