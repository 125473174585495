import React from 'react';
import { useSelector } from 'react-redux';
import CreateActions from 'Screens/Contextualization/Host/components/CreateActions';
import { Wrapper, LeftGroup, RightGroup, Separator, Group } from 'Common/Components/Contextualization/CustomActionBar/styled';
import DeleteHostButton from './components/DeleteHostButton';
import EditHostButton from './components/EditHostButton';
import Pagination from 'Common/Components/Pagination';
import { selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';
import GroupBy from './components/GroupBy';
import FilterSideBarIcon from 'Screens/Contextualization/Manage/components/CustomActionBar/components/FilterSidebarIcon';
import RefreshButton from 'Screens/Contextualization/Host/components/RefreshButton';
import AddRemoveColumnsButton from './components/AddRemoveColumnsButton';
import TagButton from './components/TagButton';
import MoveAssetsButton from './components/MoveAssetsButton';

const CustomActionBar = ({
  hostsSelected, hostsCount, showHostDetail, entity, setPage, toggleCreateModal,
  showHostModalDelete, setGroupBy, clearGroupBy, resetDefault, setFieldVisibility, setNewOrderColumns,
  isFetching, showDeleteConfirmation, fields, selectAll
}) => {
  const page = useSelector((state) => selectPage(entity, state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage(entity, state));
  const onPrev = () => setPage(page - 1);
  const onNext = () => setPage(page + 1);
  const hasHosts = hostsCount > 0;
  const showGroupBy = hasHosts && (hostsSelected.length === 0) && !showHostDetail;

  return (
    <Wrapper>
      { showDeleteConfirmation
        ? <DeleteConfirmationModal
            hostsSelected={ hostsSelected }
            showDeleteConfirmation={ showDeleteConfirmation }
            isFetching={ isFetching }
            entity={ entity }
            selectAll={ selectAll }
            hostsCount={ hostsCount }
        />
        : null
      }
      <LeftGroup>
        <CreateActions setVisibilityCreateModal={ toggleCreateModal } entity={ entity } />
        <Separator />
        <Group>
          <EditHostButton hasHosts={ hasHosts } entity={ entity } hostsSelected={ hostsSelected } />
          <TagButton hasItems={ hasHosts } hostsSelected={ hostsSelected } entity="hosts" />
          { entity === 'assets' ? <MoveAssetsButton hasHosts={ hasHosts } hostsSelected={ hostsSelected } selectAll={ selectAll } /> : null }
          <DeleteHostButton hasHosts={ hasHosts } showHostModalDelete={ showHostModalDelete } hostsSelected={ hostsSelected } showHostDetail={ showHostDetail } />
        </Group>
      </LeftGroup>

      <RightGroup>
        { showGroupBy && <GroupBy entity={ entity } setGroupBy={ setGroupBy } clearGroupBy={ clearGroupBy } /> }
        { (hasHosts) && <Pagination offset={ page } limit={ rowsPerPage } total={ hostsCount } onPrev={ onPrev } onNext={ onNext } />}
        { (hasHosts) && <Separator /> }
        <Group>
          <RefreshButton entity={ entity } />
          <FilterSideBarIcon entity={ entity } />
          <AddRemoveColumnsButton fields={ fields } resetDefault={ resetDefault } setFieldVisibility={ setFieldVisibility } setNewOrderColumns={ setNewOrderColumns } />
        </Group>
      </RightGroup>
    </Wrapper>
  );
};

export default CustomActionBar;
