import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Checkbox from 'Common/Components/Checkbox/styled';
import { openModal } from 'store/modals/actions';
import { MODAL_SERVICE_DESK_BASIC_SETUP } from 'store/modals/modals';
import { CheckboxRow, Label } from '../Auth/styled';
import { Edit, Wrapper } from './styled';
import { selectIsAuthenticated, selectIsBasicSetupConfig } from 'store/Settings/serviceDesk/selectors';
import { getDataForDropdown } from 'store/Settings/serviceDesk/actions';
import { DROPDOWN_ENDPOINTS } from 'store/Settings/serviceDesk/constants';

const BasicSetup = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const basicSetupConfigured = useSelector(selectIsBasicSetupConfig);
  // const hasSeverities = useSelector(selectHasSeverities);
  // const hasStatuses = useSelector(selectHasStatuses);
  const intl = useIntl();
  const dispatch = useDispatch();

  const open = () => {
    dispatch(getDataForDropdown(DROPDOWN_ENDPOINTS.priority));
    dispatch(getDataForDropdown(DROPDOWN_ENDPOINTS.status));
    // if (hasSeverities && hasStatuses) dispatch(openModal(MODAL_SERVICE_DESK_BASIC_SETUP));
    dispatch(openModal(MODAL_SERVICE_DESK_BASIC_SETUP));
  };

  const onChange = () => {
    if (isAuthenticated) open();
  };

  return (
    <Wrapper>
      <CheckboxRow>
        <Label>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.basicSetup' }) }</Label>
        <Checkbox checked={ basicSetupConfigured } onChange={ onChange } />
        <Edit onClick={ (isAuthenticated && basicSetupConfigured) ? open : null } basicSetupFilled={ basicSetupConfigured }>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.edit' }) }</Edit>
      </CheckboxRow>
    </Wrapper>
  );
};

export default BasicSetup;
