import styled from 'styled-components';
import colors from 'Styles/colors';
import DebouncedInput from 'Common/Components/v2/DebouncedInput';
import { Input } from 'Common/Components/StandardTextField/styled';
import ToggleInput from 'Common/Components/ToggleInput';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const Row = styled.div`
  display: flex;
  align-items: center;
`;
Row.displayName = 'Row';

export const CustomInput = styled(DebouncedInput)`
  span {
    font-weight: 400;
    margin-right: 3px;
  }
  ${Input} {
    box-shadow: inset 0 -1px 0 0 #afafaf;
    &::placeholder,
    &::-webkit-input-placeholder {
      font-size: 14.5px;
      color: ${colors.grey12};
    }
  }
`;
CustomInput.displayName = 'CustomInput';

export const Url = styled(CustomInput)`
  margin-right: 23px;
  width: 267px;
`;
Url.displayName = 'Url';

export const WrapperToggle = styled.div`
  margin-right: 10px;
  padding: 10px 0;
  display: flex;
`;
WrapperToggle.displayName = 'WrapperToggle';

export const ApiToken = styled(ToggleInput)`
  width: 320px;
`;
ApiToken.displayName = 'ApiToken';

export const CheckboxRow = styled(Row)`
  margin-top: 5px;
`;
CheckboxRow.displayName = 'CheckboxRow';

export const Label = styled.span`
  font-size: 14.5px;
  color: ${colors.grey17};
  width: 267px;
  text-align: end;
  margin-right: 24px;
  position: relative;
  &:after {
    content: '*';
    color: ${colors.redPink};
    position: absolute;
    top: 1px;
    margin-left: 3px;
  }
`;
Label.displayName = 'Label';
