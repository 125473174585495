import styled from 'styled-components';
import colors from 'Styles/colors';
import { ellipsisText } from 'Styles/styles';

export const Wrapper = styled.div`
  width: 431px;
  & >:nth-child(2) {
    margin-top: 20px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const List = styled.div`
  border-radius: 3px;
  box-shadow: 0 2px 50px 0 ${colors.iceBlue};
  margin-top: 8px;
  & >:nth-child(odd) {
    background-color: ${colors.white};
  }
  & >:nth-child(even) {
    background-color: ${colors.iceBlue};
  }
`;
List.displayName = 'List';

export const Row = styled.div`
  display: flex;
  padding: 6px 0;
`;
Row.displayName = 'Row';

export const Fieldname = styled.div`
  font-size: 14.5px;
  color: ${colors.darkBlueGrey};
  width: 125px;
  margin: 7px 0 6px 20px;
  ${ellipsisText}
`;
Fieldname.displayName = 'Fieldname';

export const FieldInput = styled.div`
  width: 234px;
  .sd-optional-fields-text {
    height: 26px;
  }
`;
FieldInput.displayName = 'FieldInput';

export const TrashWrapper = styled.div`
  margin-left: 20px;
`;
TrashWrapper.displayName = 'TrashWrapper';

export const dropdownStyle = {
  control: (provided) => ({
    display: 'flex',
    minHeight: '38px',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 #afafaf',
    backgroundColor: '#edf2f7',
    borderBottomWidth: '0px',
    color: 'white',
    paddingLeft: '10px',
    fontWeight: '700',
    textOverflow: 'ellipsis',
    borderLeft: 'none',
    cursor: 'pointer'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14.5px',
    color: '#afb1bc',
    fontWeight: 400
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '13px',
    color: '#1c2243',
    fontWeight: 300
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#505965',
    width: '30px'
  }),
  option: (provided, { isFocused, data }) => ({
    ...provided,
    backgroundColor: isFocused ? '#edf2f7' : 'white',
    fontSize: '13.5px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: isFocused ? 'pointer' : 'default',
    paddingLeft: '22px',
    paddingRight: '22px',
    height: '36px',
    textTransform: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }),
  valueContainer: () => ({
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    marginRight: 'auto'
  }),
  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '190px'
  }),
  menuList: () => ({
    maxHeight: '152px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '10px',
    paddingBottom: '13px'
  }),
  singleValue: (provided, state) => {
    const transition = 'opacity 300ms';
    return {
      ...provided, transition, color: '#1c2243', width: 'fit-content', height: '19px', fontSize: '14px', fontWeight: 400, top: '8px', position: 'relative'
    };
  }
};
