import React, { useState, useEffect, useRef } from 'react';
import {
  Asterisk, Texts, ErrorMessage, Warning
} from 'Common/Components/StandardTextField/styled';
import { Wrapper, Input, Title, Toggle } from './styled';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

const ToggleInput = ({
  focusOnMount, value, name, disabled, onChange,
  placeholder, title, mandatory, error, errorMessage,
  className, boldTitle, id, autocomplete, showPlainText
}) => {
  const [fieldValue, setFieldValue] = useState('');
  const [showText, setShowText] = useState(showPlainText);
  const inputRef = useRef(null);

  useEffect(() => {
    const currentRef = get(inputRef, 'current', null);
    if (focusOnMount && currentRef) {
      currentRef.focus();
      setShowText(true);
    }
  }, [focusOnMount]);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  const updateName = useRef(debounce((v) => {
    if (name) onChange(v);
  }, 250));

  const handleChange = (e) => {
    const v = e.target.value;
    setFieldValue(v);
    updateName.current(v);
  };

  return (
    <Wrapper className={ className }>
      <Texts>
        {title && <Title boldTitle={ boldTitle }>{title}</Title>}
        {mandatory && <Asterisk>*</Asterisk>}
      </Texts>
      <Input
        name={ name }
        value={ fieldValue }
        disabled={ disabled }
        onChange={ handleChange }
        isEmpty={ value ? value.length === 0 : true }
        placeholder={ placeholder }
        error={ error }
        type={ showText ? 'text' : 'password' }
        ref={ inputRef }
        id={ id }
        autoComplete={ autocomplete }
      />
      <Toggle onClick={ () => setShowText(!showText) } />
      {error && <Warning />}
      {error && <ErrorMessage>{ errorMessage }</ErrorMessage>}
    </Wrapper>

  );
};

export default ToggleInput;
