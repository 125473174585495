import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectApiToken, selectIsAuthenticated, selectUrl } from 'store/Settings/serviceDesk/selectors';
import { authenticateServiceDesk, setField, deauthenticated } from 'store/Settings/serviceDesk/actions';
import Checkbox from 'Common/Components/Checkbox/styled';
import { Wrapper, Row, Url, ApiToken, CheckboxRow, Label } from './styled';

const Auth = () => {
  const url = useSelector(selectUrl);
  const apiToken = useSelector(selectApiToken);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useDispatch();
  const intl = useIntl();
  const change = (field, value) => {
    dispatch(setField(field, value));
    if (isAuthenticated) {
      dispatch(deauthenticated());
    }
  };

  const auth = () => {
    if (url && apiToken) dispatch(authenticateServiceDesk());
  };

  return (
    <Wrapper>
      <Row>
        <Url
          title={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.url.title' }) }
          placeholder={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.url.placeholder' }) }
          onChange={ (v) => { change('url', v); } }
          value={ url }
          defaultValue={ url }
          autocomplete="off"
          id="service-desk-url"
          name="url"
          mandatory
          focusOnMount={ !url }
        />
        <ApiToken
          title={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.apiToken.title' }) }
          placeholder={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.apiToken.placeholder' }) }
          onChange={ (v) => { change('api_token', v); } }
          value={ apiToken }
          defaultValue={ apiToken }
          autocomplete="new-password"
          id="service-desk-api-token"
          name="apiToken"
          mandatory
        />
      </Row>
      <CheckboxRow>
        <Label>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.auth' }) }</Label>
        <Checkbox checked={ isAuthenticated } onChange={ auth } />
      </CheckboxRow>
    </Wrapper>
  );
};

export default Auth;
