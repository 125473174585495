import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import TextField from '../TextField';
import DropdownField from '../DropdownField';
import SearchField from '../SearchField';
import { SectionTitle } from '../../styled';
import { selectMandatoryFieldsTemplate } from 'store/Settings/serviceDesk/selectors';
import GroupedDropdown from '../GroupedDropdown';

const MandatoryFields = () => {
  const mandatoryFields = useSelector(selectMandatoryFieldsTemplate);
  // const mandatoryFields = [{ name: 'asset', type: 'unknown' }, { name: 'request_type', type: 'dropdown' }, { name: 'site', type: 'search' }, { name: 'user', type: 'search' }, { name: 'usersagain', type: 'search' }];
  const intl = useIntl();
  return (
    <>
      <SectionTitle>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.template.mandatory' }) }</SectionTitle>
      { mandatoryFields && mandatoryFields.map((field, i) => (
        <div key={ `${field.name}-${i}` }>
          { ((field.type === 'dropdown') && (field.name === 'category')) ? <GroupedDropdown fieldName={ field.name } mandatory /> : null }
          { field.type === 'unknown' ? <TextField fieldName={ field.name } mandatory /> : null }
          { ((field.type === 'dropdown') && (field.name !== 'category')) ? <DropdownField fieldName={ field.name } mandatory /> : null }
          { field.type === 'search' ? <SearchField fieldName={ field.name } mandatory /> : null }
        </div>
      ))
      }
    </>
  );
};

export default MandatoryFields;
