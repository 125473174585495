import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as ArrowCollapsed } from 'Images/wf_arrow_collapsed.svg';
import { ReactComponent as ArrowExpanded } from 'Images/wf_arrow_expand.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f9f9f9;
  position: relative;
  border-radius: 3px;
  margin-top: 17px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 14.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: normal;
  color: ${colors.darkBlueGrey};
  user-select: none;
  text-align: left;
  padding: 0 0 11px 9px;
  &:after {
    content: '*';
    color: ${colors.redPink};
    position: absolute;
    margin-left: 3px;
  }
`;
Title.displayName = 'Title';

export const Header = styled.div`
  display: flex;
  flex: 0 0 44px;
  padding: 9px 20px 0 11px;
  align-items: center;
  cursor: pointer;
  & > * {
    margin-right: 10px;
  }
`;
Header.displayName = 'Header';

export const Button = styled.button`
  position: absolute;
  top: 49px;
  right: 15px;
  width: 19px;
  height: 18px;
  display: flex;
  border: none;
  background-color: transparent;
`;
Button.displayName = 'Button';

export const ExpandedIcon = styled(ArrowExpanded)`
  margin: auto;
  width: 12px;
  color: #63758d;
  path {
    fill: ${colors.greyBlue};
  }
`;
ExpandedIcon.displayName = 'ExpandedIcon';

export const CollapsedIcon = styled(ArrowCollapsed)`
  margin: auto;
  height: 12px;
  path {
    fill: ${colors.greyBlue};
  }
`;
CollapsedIcon.displayName = 'CollapsedIcon';

export const ChildWrapper = styled.div.attrs(({ containerHeight }) => {
  let height = '';
  if (containerHeight) height = `${containerHeight}px`;
  return ({ style: { height } });
})`
  ${({ $isExpanded }) => (!$isExpanded && 'display: none')};
  overflow: visible;
`;
ChildWrapper.displayName = 'ChildWrapper';

export const Items = styled.div`
  display: flex;
  flex-direction: column;
`;
Items.displayName = 'Items';

export const HeaderChild = styled.div`

`;
HeaderChild.displayName = 'HeaderChild';
