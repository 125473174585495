import get from 'lodash/get';
import api from 'services/api';
import { trackEvent } from 'tracking/GA';
import { ACTIONS, CATEGORIES } from 'tracking/GA/constants';
import { closeModal } from 'store/modals/actions';
import { MODAL_RUN_USER_AGENT, MODAL_RUN_CLOUD_AGENT } from 'store/modals/modals';
import { selectActiveWorkspaces } from 'store/Workspace/selectors';
import { setError } from 'store/errors/actions';
import * as types from './types';
import {
  selectTriggerAgentWs, selectTriggerIgnoreInfo, selectTriggerResolveHostname,
  selectSelectedCloudAgentId, selectAgentList, selectAgentsSelected, selectLastSelected,
  selectTriggerAgentId, SelectShowCloudAgents, selectAssetsTags, selectVulnsTags,
  selectServicesTags
} from './selectors';

export const resetState = () => (dispatch) => dispatch({ type: types.AGENTS_RESET_STATE });

function fetching () {
  return async (dispatch) => {
    dispatch({ type: types.AGENTS_FETCHING });
  };
}

function somethingWentWrong (errors) {
  return async (dispatch) => {
    dispatch({ type: types.AGENTS_FAIL, errorMessage: 'There was an error, please try again.', errors });
  };
}

export function getUserAgents () {
  return async (dispatch) => {
    try {
      dispatch(fetching());
      const userAgents = await api.agents.fetchUserAgents();
      dispatch({ type: types.AGENTS_GET_USER_AGENTS, userAgents });
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function getCloudAgents () {
  return async (dispatch) => {
    try {
      dispatch(fetching());
      const response = await api.agents.fetchCloudAgents();
      dispatch({
        type: types.AGENTS_GET_CLOUD_AGENTS,
        cloudAgents: response
      });
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function removeAgent (agent) {
  return async (dispatch) => {
    try {
      dispatch(fetching());
      await api.agents.removeAgent(agent.id);
      dispatch(trackEvent(CATEGORIES.agent, ACTIONS.deleteAgent.name, ACTIONS.deleteAgent.label, agent.id));
      dispatch(getUserAgents());
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function getToken () {
  return async (dispatch) => {
    try {
      dispatch(fetching());
      const responseSession = await api.faraday.getSession();

      const fd = new FormData();
      fd.set('csrf_token', responseSession.csrf_token);

      const responseToken = await api.agents.fetchToken(fd);
      dispatch({
        type: types.AGENTS_GET_TOKEN, token: responseToken.token, tokenExpiresIn: responseToken.expires_in, tokenDuration: responseToken.total_duration
      });
    } catch (e) {
      dispatch(somethingWentWrong(e));
    }
  };
}

export function getAgentTools (id) {
  return async (dispatch) => {
    try {
      dispatch(fetching());
      const response = await api.agents.fetchAgentTools(id);
      dispatch({ type: types.AGENTS_GET_TOOLS, id, tools: response.executors });
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function setAgentId (id) {
  return async (dispatch) => {
    dispatch({ type: types.AGENTS_SET_ID, id });
  };
}

export function runUserAgentTool (toolName, toolParams, tags) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const agentId = selectTriggerAgentId(state);
      const workspaces = selectTriggerAgentWs(state).map((ws) => ws.name);
      const ignoreInfo = selectTriggerIgnoreInfo(state);
      const resolveHostname = selectTriggerResolveHostname(state);
      const assetsTags = selectAssetsTags(state);
      const vulnsTags = selectVulnsTags(state);
      const servicesTags = selectServicesTags(state);
      const parsedTags = tags ? tags.split(',').map((t) => t.trim()) : [];
      const newTags = [...new Set(parsedTags)];
      dispatch(fetching());
      const payload = {
        ignore_info: ignoreInfo,
        resolve_hostname: resolveHostname,
        workspaces_names: workspaces,
        executor_data: {
          agent_id: agentId,
          executor: toolName,
          args: toolParams
        },
        vuln_tag: vulnsTags ? newTags : [],
        service_tag: servicesTags ? newTags : [],
        host_tag: assetsTags ? newTags : []
      };
      await api.agents.runUserAgentTool(agentId, payload);
      dispatch(trackEvent(CATEGORIES.agent, ACTIONS.runAgent.name, ACTIONS.runAgent.label, agentId));

      dispatch(getUserAgents());
      dispatch(closeModal(MODAL_RUN_USER_AGENT));
      dispatch({ type: types.AGENTS_SUCCESS });
      dispatch(setTagsEntity('assetsTags', false));
      dispatch(setTagsEntity('vulnsTags', false));
      dispatch(setTagsEntity('servicesTags', false));
    } catch (e) {
      dispatch(somethingWentWrong(e));
    }
  };
}

export function setSelectedCloudAgent (cloudAgent) {
  return (dispatch) => {
    dispatch({ type: types.AGENTS_SELECT_CLOUD_AGENT, cloudAgent });
  };
}

export function runCloudAgent (args) {
  return async (dispatch, getState) => {
    try {
      const agentId = selectSelectedCloudAgentId(getState());
      const workspaces = selectTriggerAgentWs(getState()).map((ws) => ws.name);
      dispatch(fetching());
      const payload = {
        workspaces,
        args
      };
      await api.agents.runCloudAgent(agentId, payload);
      dispatch(trackEvent(CATEGORIES.cloudAgent, ACTIONS.runCloudAgent.name, ACTIONS.runCloudAgent.label, agentId));
      dispatch(getCloudAgents());
      dispatch(closeModal(MODAL_RUN_CLOUD_AGENT));
      dispatch({ type: types.AGENTS_SUCCESS });
    } catch (e) {
      const error = get(e, 'message', 'There was an error, please try again.');
      dispatch(setError(error));
    }
  };
}

export function runCloudAgentTool (wsSelected, toolParams, tags) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const agentId = selectTriggerAgentId(state);
      const assetsTags = selectAssetsTags(state);
      const vulnsTags = selectVulnsTags(state);
      const servicesTags = selectServicesTags(state);
      dispatch(fetching());
      const workspaces = wsSelected.map(w => w.primary);
      const parsedTags = tags ? tags.split(',').map((t) => t.trim()) : [];
      const newTags = [...new Set(parsedTags)];

      const payload = {
        workspaces,
        args: toolParams,
        vuln_tag: vulnsTags ? newTags : [],
        service_tag: servicesTags ? newTags : [],
        host_tag: assetsTags ? newTags : []
      };

      await api.agents.runCloudAgent(agentId, payload);

      dispatch(getCloudAgents());
      dispatch(closeModal(MODAL_RUN_USER_AGENT));
      dispatch({ type: types.AGENTS_SUCCESS });
      dispatch(setTagsEntity('assetsTags', false));
      dispatch(setTagsEntity('vulnsTags', false));
      dispatch(setTagsEntity('servicesTags', false));
    } catch (e) {
      dispatch(somethingWentWrong(e));
    }
  };
}

export function resetWizard () {
  return async (dispatch) => {
    dispatch({ type: types.AGENTS_CREATE_WIZARD_RESET });
  };
}

export function addTool (toolName) {
  return async (dispatch) => {
    dispatch({ type: types.AGENTS_CREATE_WIZARD_ADD_TOOL, tool: { name: toolName, variables: [] } });
  };
}

export function removeTool (tool) {
  return async (dispatch) => {
    dispatch({ type: types.AGENTS_CREATE_WIZARD_REMOVE_TOOL, tool });
  };
}

export function setFieldsTool (toolName, fields) {
  return async (dispatch) => {
    dispatch({ type: types.AGENTS_CREATE_WIZARD_SET_FIELDS_TOOL, toolName, fields });
  };
}

export function getManifest () {
  return async (dispatch) => {
    try {
      dispatch(fetching());
      const data = await api.manage.fetchManifest();
      dispatch({ type: types.AGENTS_GET_MANIFEST, data });
    } catch (e) {
      dispatch(somethingWentWrong(e));
    }
  };
}

export const setAgent = (data) => (dispatch) => {
  dispatch({ type: types.SET_NEW_AGENT, data });
};

export function clearAgent () {
  return (dispatch) => {
    dispatch({ type: types.RESET_NEW_AGENT });
  };
}

export function setTriggerAgentWs (workspaces) {
  return (dispatch) => {
    dispatch({ type: types.AGENTS_RUN_SET_WORKSPACES, workspaces });
  };
}

export function addTriggerAgentWs (ws) {
  return (dispatch, getState) => {
    const addedWorkspaces = selectTriggerAgentWs(getState());
    const allWorkspaces = selectActiveWorkspaces(getState());

    const newWs = allWorkspaces.find((workspace) => workspace.id === ws.id);
    if (newWs) {
      const newWorkspaces = [
        ...addedWorkspaces.filter((workspace) => workspace.id !== ws.id),
        newWs
      ];

      dispatch(setTriggerAgentWs(newWorkspaces));
    }
  };
}

export function removeTriggerAgentWs (ws) {
  return (dispatch, getState) => {
    const addedWorkspaces = selectTriggerAgentWs(getState());
    const newWorkspaces = addedWorkspaces.filter((w) => w.id !== ws.id);
    dispatch(setTriggerAgentWs(newWorkspaces));
  };
}

export function setIgnoreInfo (ignoreInfo) {
  return (dispatch) => {
    dispatch({ type: types.AGENTS_RUN_SET_IGNORE_INFO, ignoreInfo });
  };
}

export function setResolveHostname (resolveHostname) {
  return (dispatch) => {
    dispatch({ type: types.AGENTS_RUN_SET_RESOLVE_HOSTNAME, resolveHostname });
  };
}

// Click Logic

const addDeleteController = (agents, agentList) => {
  const allAgentsAreSelected = agents.every((testAgent) => agentList.some((agent) => agent.id === testAgent.id));

  if (allAgentsAreSelected) return [types.AGENT_UNSELECTED, agents];
  return [types.AGENT_SELECTED, agents];
};

const selectCalculator = (e, agent, agentList, areAgentsSelected) => (dispatch, getState) => {
  const pivot = selectLastSelected(getState());
  const index = agentList.findIndex((el) => el.id === agent.id);
  const agentsSelected = selectAgentsSelected(getState());

  dispatch({ type: types.NEW_PIVOT, payload: index });
  if (e.shiftKey && pivot !== -1 && areAgentsSelected) {
    const start = Math.min(pivot, index);
    const end = Math.max(pivot, index) + 1;
    const agents = agentList.slice(start, end);
    const [type, payload] = addDeleteController(agents, agentsSelected);
    return dispatch({ type, payload });
  }
  const [type, payload] = addDeleteController([agent], agentsSelected);
  return dispatch({ type, payload });
};

export const selectRow = (e, agent) => (dispatch, getState) => {
  const allAgents = selectAgentList(getState());
  const agentSelected = selectAgentsSelected(getState());
  dispatch(selectCalculator(e, agent, allAgents, agentSelected.length > 0));
};

export const unSelectAll = () => (dispatch) => dispatch({ type: types.UNSELECT_ALL_AGENT });

export const selectAllAgents = () => (dispatch, getState) => {
  const agentsList = selectAgentList(getState());
  const agentsSelected = selectAgentsSelected(getState());

  if (agentsSelected.length === agentsList.length) return dispatch(unSelectAll());

  return dispatch({ type: types.SELECT_ALL_AGENT, agentsList });
};

export const toggleAgentsTab = (bool) => (dispatch) => {
  dispatch({ type: types.TOGGLE_AGENTS_TAB, payload: bool });
};

export function setTagsEntity (entity, value) {
  return (dispatch) => {
    dispatch({ type: types.AGENTS_RUN_SET_TAGS_ENTITY, entity, value });
  };
}

export function changeStateAgent (agent) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const agentId = selectTriggerAgentId(state);
      dispatch(fetching());
      const payload = {
        name: agent?.name,
        active: !agent?.active
      };
      await api.agents.changeStateAgent(agent.id, payload);
      dispatch(trackEvent(CATEGORIES.agent, ACTIONS.runAgent.name, ACTIONS.runAgent.label, agentId));
      dispatch(getUserAgents());
      dispatch(closeModal(MODAL_RUN_USER_AGENT));
      dispatch({ type: types.AGENTS_SUCCESS });
    } catch (e) {
      dispatch(somethingWentWrong(e));
    }
  };
}

export function removeSelectedAgents () {
  return async (dispatch, getState) => {
    try {
      const showCloudAgents = SelectShowCloudAgents(getState());
      const agentsSelected = selectAgentsSelected(getState());
      dispatch(fetching());

      const removeFunction = showCloudAgents ? api.agents.removeCloudAgent : api.agents.removeAgent;

      const removalPromises = agentsSelected.map(agent => removeFunction(agent.id));
      await Promise.all(removalPromises);

      dispatch(trackEvent(CATEGORIES.agent, ACTIONS.deleteAgent.name, ACTIONS.deleteAgent.label, agentsSelected[0].id));
      dispatch(getUserAgents());
      dispatch(unSelectAll());
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}
