import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as Plus } from 'Images/icon-action-bar-plus.svg';

export const Wrapper = styled.div`
  width: 320px;
  /* position: relative; */
`;

Wrapper.displayName = 'SearchListWrapper';

export const PlusIcon = styled(Plus)`
  position: absolute;
  right: 10px;
  top: 13px;
  width: 10px;
  & > path {
    fill: ${colors.blueGrey};
  }
`;

PlusIcon.displayName = 'PlusIcon';

export const dropdownStyle = {
  control: (provided, { isDisabled }) => ({
    display: 'flex',
    minHeight: '35px',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 #afafaf',
    backgroundColor: '#edf2f7',
    borderBottomWidth: '0px',
    color: 'white',
    paddingLeft: '10px',
    fontWeight: '700',
    textOverflow: 'ellipsis',
    borderLeft: isDisabled ? '12px solid #afb1bc' : '12px solid #53a9ff',
    cursor: 'pointer',
    opacity: isDisabled ? '0.5' : '1'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14.5px',
    color: '#afb1bc',
    fontWeight: 400
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '13px',
    color: '#1c2243',
    fontWeight: 300
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#505965',
    width: '30px'
  }),
  option: (provided, { isFocused, data }) => ({
    ...provided,
    backgroundColor: isFocused ? '#edf2f7' : 'white',
    fontSize: '13.5px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: isFocused ? 'pointer' : 'default',
    paddingLeft: '22px',
    paddingRight: '22px',
    height: '36px',
    textTransform: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }),
  valueContainer: () => ({
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    marginRight: 'auto'
  }),
  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '190px'
  }),
  menuList: () => ({
    maxHeight: '152px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '10px',
    paddingBottom: '13px'
  }),
  singleValue: (provided, state) => {
    const transition = 'opacity 300ms';
    return {
      ...provided, transition, color: '#1c2243', width: 'fit-content', height: '19px', fontSize: '14px', fontWeight: 400, top: '8px', position: 'relative'
    };
  }
};
