import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectError, selectErrorMessage } from 'store/Settings/serviceDesk/selectors';
import Error from '../../../Error';
import { setField } from 'store/Settings/serviceDesk/actions';
import Auth from './components/Auth';
import { Wrapper, Title, Content, Subtitle } from './styled';
import BasicSetup from './components/BasicSetup';
import Templates from './components/Templates';
import BasicSetupModal from './components/BasicSetupModal';
import ConfigureTemplateModal from './components/ConfigureTemplateModal';
import selectModal from 'store/modals/selectors';
import {
  MODAL_SERVICE_DESK_BASIC_SETUP, MODAL_SERVICE_DESK_CONFIGURE_TEMPLATE
} from 'store/modals/modals';

const MainSettings = () => {
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);
  const dispatch = useDispatch();
  const intl = useIntl();
  const showBasicSetupModal = useSelector((state) => selectModal(state, MODAL_SERVICE_DESK_BASIC_SETUP));
  const showConfigureTemplate = useSelector((state) => selectModal(state, MODAL_SERVICE_DESK_CONFIGURE_TEMPLATE));

  const onCloseError = () => {
    dispatch(setField('error', false));
    dispatch(setField('errorMessage', ''));
  };

  return (
    <Wrapper>
      <Title children='Main settings' />
      <Content>
        <Subtitle>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.title' }) }</Subtitle>
        <Auth />
        <BasicSetup />
        <Templates />
        { showBasicSetupModal && <BasicSetupModal /> }
        { showConfigureTemplate && <ConfigureTemplateModal /> }
        { error && errorMessage ? <Error message={ errorMessage } close={ onCloseError } /> : null }
      </Content>
    </Wrapper>
  );
};

export default MainSettings;
