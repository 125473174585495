import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ExpandableWrapper from '../ExpandableWrapper';
import Select from 'react-select';
import {
  Wrapper, Header, Title, Line,
  Content, Row, Col,
  dropdownStyle, TabWrapper, Tab,
  Tag,
  ArrowLeft,
  ArrowRight
} from '../Severity/styled';
import {
  selectStatusOutgoingParsed, selectStatusOutgoing, selectOptionsData,
  selectStatusIncoming
} from 'store/Settings/serviceDesk/selectors';
import { setStatusType } from 'store/Settings/serviceDesk/actions';
import { DROPDOWN_ENDPOINTS } from 'store/Settings/serviceDesk/constants';
import { HelpIcon } from '../../styled';

const HeaderChildren = ({ selectedTab, handleTabClick }) => {
  return (
    <TabWrapper>
      <Tab active={ selectedTab === 'incoming' } onClick={ () => handleTabClick('incoming') }>Incoming</Tab>
      <Tab active={ selectedTab === 'outgoing' } onClick={ () => handleTabClick('outgoing') }>Outgoing</Tab>
    </TabWrapper>
  );
};

const Status = () => {
  const intl = useIntl();
  const [selectedTab, handleTabClick] = useState('incoming');
  const dispatch = useDispatch();
  const status = useSelector((state) => selectOptionsData(state, DROPDOWN_ENDPOINTS.status));
  // const status = ["Open", "Assigned", "In Progress", "On Hold", "Closed", "Resolved"]
  const optionsOutgoing = status.map((s) => ({ label: s, value: s }));
  const optionsIncoming = [
    { label: 'Open', value: 'open' },
    { label: 'Closed', value: 'closed' },
    { label: 'Re-Opened', value: 're-opened' },
    { label: 'Risk-Accepted', value: 'risk-accepted' },
    { label: 'Ignored', value: 'Ignored' }
  ];
  const statusOutgoingParsed = useSelector(selectStatusOutgoingParsed);
  const statusOutgoing = useSelector(selectStatusOutgoing);
  const statusIncoming = useSelector(selectStatusIncoming);
  const help = intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.basicSetup.help' });

  const onSelect = (s, value, type) => {
    const obj = { [s]: value };
    dispatch(setStatusType(obj, type));
  };

  return (
    <ExpandableWrapper id="service-desk-basic-setup-status" expanded title={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.basicSetup.status' }) } headerChildren={ <HeaderChildren selectedTab={ selectedTab } handleTabClick={ handleTabClick } /> } >
      <Wrapper>
        <Header>
            <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.basicSetup.status.col1' }) }</Title>
            <Line />
            <Title>
              { intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.basicSetup.status.col3' }) }
              <HelpIcon title={ help } />
            </Title>
        </Header>
        { selectedTab === 'outgoing'
          ? <Content>
          { statusOutgoingParsed && statusOutgoingParsed.map((s) => (
            <Row key={ s.name }>
              <Col>{ s.name }</Col>
              <Col>{ statusOutgoing?.[s.name] && (statusOutgoing?.[s.name] !== 'Ignored') ? <Tag><ArrowRight /></Tag> : null }</Col>
              <Col>
                <Select
                  value={ { label: statusOutgoingParsed.find((item) => s.name === item.name)?.value, value: statusOutgoingParsed.find((item) => s.name === item.name)?.value } }
                  onChange={ (item) => onSelect(s.name, item.value, 'outgoing') }
                  options={ optionsOutgoing }
                  styles={ dropdownStyle }
                  isSearchable={ false }
                />
              </Col>
            </Row>
          ))}
            </Content>
          : <Content>
            { status && status.map((s, i) => (
              <Row key={ s }>
                <Col>
                  <Select
                    value={ { label: statusIncoming[s] || '', value: statusIncoming[s] || '' } }
                    onChange={ (item) => onSelect(s, item.value, 'incoming') }
                    options={ optionsIncoming }
                    styles={ dropdownStyle }
                    isSearchable={ false }
                  />
                </Col>
                <Col>{ statusIncoming?.[s] && (statusIncoming?.[s] !== 'Ignored') ? <Tag><ArrowLeft /></Tag> : null }</Col>
                <Col>{ s }</Col>
              </Row>
            ))
            }
            </Content>
        }
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default Status;
