import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Account from 'Screens/Contextualization/Settings/components/Account';
import Report from 'Screens/Contextualization/Settings/components/Report';
import Smtp from 'Screens/Contextualization/Settings/components/Smtp';
import Saml from 'Screens/Contextualization/Settings/components/Saml';
import Ldap from 'Screens/Contextualization/Settings/components/Ldap';
import TicketingTools from 'Screens/Contextualization/Settings/components/TicketingTools';
import { ReactComponent as AccountIcon } from 'Images/preferences_icons_Account.svg';
import { ReactComponent as TicketingIcon } from 'Images/preferences_icons_Ticketing.svg';
import { ReactComponent as AuthIcon } from 'Images/preferences_icons_Authentication.svg';
import { ReactComponent as IntegrationsIcon } from 'Images/customAttributes.svg';
import { ReactComponent as CommandIcon } from 'Images/icon-customfields.svg';
import {
  PERMISSION_TYPES,
  FEATURE_SETTINGS_REPORTING, FEATURE_SETTINGS_SMTP,
  FEATURE_SETTINGS_SAML, FEATURE_SETTINGS_LDAP, FEATURE_CUSTOM_FIELDS,
  FEATURE_USER_TOKENS
} from 'Hooks/usePermission/permissions';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import {
  Wrapper,
  Tabs,
  TabGroup,
  Title,
  Header,
  IconWrapper,
  Subtitle,
  Item,
  TabContent,
  Content
} from './styled';
import 'Screens/Contextualization/Settings/styles.scss';
import CustomAttributes from '../CustomAttributes';
import { selectIsAdmin } from 'store/Faraday/selectors';
import NotificationsPreferences from 'Screens/Contextualization/Settings/components/Notifications';
import AccessTokens from '../AccessTokens';
import UserSessions from '../UserSessions';
import ServiceDesk from '../TicketingTools/components/ServiceDesk';

const Menu = ({ onClose, defaultTab = 'account' }) => {
  const defaultVisibleComponent = defaultTab === 'notifications' ? <NotificationsPreferences onClose={ onClose } /> : <Account onClose={ onClose } />;
  const intl = useIntl();
  const [visibleComponent, setVisibleComponent] = useState(defaultVisibleComponent);
  const [active, setActive] = useState(defaultTab);
  const isAccountEnabled = true;

  const isAdmin = useSelector(selectIsAdmin);

  const isReportsEnabled = useSelector((state) => selectAllowedByRole(state, FEATURE_SETTINGS_REPORTING, PERMISSION_TYPES.PATCH));
  const isSamlEnabled = useSelector((state) => selectAllowedByRole(state, FEATURE_SETTINGS_SAML, PERMISSION_TYPES.PATCH) && selectAllowedByFeature(state, FEATURE_SETTINGS_SAML, PERMISSION_TYPES.PATCH));
  const isSmtpEnabled = useSelector((state) => selectAllowedByRole(state, FEATURE_SETTINGS_SMTP, PERMISSION_TYPES.PATCH) && selectAllowedByFeature(state, FEATURE_SETTINGS_SMTP, PERMISSION_TYPES.PATCH));
  const isLdapEnabled = useSelector((state) => selectAllowedByRole(state, FEATURE_SETTINGS_LDAP, PERMISSION_TYPES.PATCH) && selectAllowedByFeature(state, FEATURE_SETTINGS_LDAP, PERMISSION_TYPES.PATCH));
  const isCustomAtributespEnabled = useSelector((state) => selectAllowedByRole(state, FEATURE_CUSTOM_FIELDS, PERMISSION_TYPES.PATCH) && selectAllowedByFeature(state, FEATURE_CUSTOM_FIELDS, PERMISSION_TYPES.PATCH));
  const isUserTokensEnabled = useSelector((state) => selectAllowedByRole(state, FEATURE_USER_TOKENS, PERMISSION_TYPES.READ) && selectAllowedByFeature(state, FEATURE_USER_TOKENS, PERMISSION_TYPES.READ));

  const click = (key, component) => { setActive(key); setVisibleComponent(component); };
  const menuItems = [
    {
      canView: isAccountEnabled || isReportsEnabled,
      items: [
        {
          key: 'account', title: intl.formatMessage({ id: 'preferences.account.title' }), click: () => { click('account', <Account onClose={ onClose } />); }, icon: <AccountIcon />, canView: true
        }
      ]
    },
    {
      title: intl.formatMessage({ id: 'preferences.instanceSettings.title' }),
      canView: isAccountEnabled || isReportsEnabled,
      icon: <CommandIcon />,
      items: [
        {
          key: 'userSessions', title: intl.formatMessage({ id: 'preferences.userSessions.title' }), click: () => { click('userSessions', <UserSessions onClose={ onClose } />); }, canView: isReportsEnabled
        },
        {
          key: 'report', title: 'Report', click: () => { click('report', <Report onClose={ onClose } />); }, canView: isReportsEnabled
        },
        {
          key: 'customAttributes', title: intl.formatMessage({ id: 'preferences.customAttributes.title' }), click: () => { click('customAttributes', <CustomAttributes onClose={ onClose } />); }, canView: isCustomAtributespEnabled
        }
      ]
    },
    {
      title: intl.formatMessage({ id: 'preferences.authentication.title' }),
      canView: isSamlEnabled || isLdapEnabled || isUserTokensEnabled,
      icon: <AuthIcon />,
      items: [
        {
          key: 'ldap', title: intl.formatMessage({ id: 'preferences.ldap.title' }), click: () => { click('ldap', <Ldap onClose={ onClose } />); }, canView: isLdapEnabled
        },
        {
          key: 'saml', title: intl.formatMessage({ id: 'preferences.saml.title' }), click: () => { click('saml', <Saml onClose={ onClose } />); }, canView: isSamlEnabled
        },
        {
          key: 'accessTokens', title: intl.formatMessage({ id: 'preferences.accessTokens.title' }), click: () => { click('accessTokens', <AccessTokens onClose={ onClose } />); }, canView: isUserTokensEnabled
        }
      ]
    },
    {
      title: intl.formatMessage({ id: 'preferences.ticketingTools.title' }),
      canView: isAdmin,
      icon: <TicketingIcon />,
      items: [
        {
          key: 'ticketing', title: intl.formatMessage({ id: 'preferences.ticketingTools.title' }), click: () => { click('ticketing', <TicketingTools onClose={ onClose } />); }, canView: isAdmin
        },
        {
          key: 'serviceDesk', title: intl.formatMessage({ id: 'preferences.serviceDesk.title' }), click: () => { click('serviceDesk', <ServiceDesk onClose={ onClose } />); }, canView: isAdmin
        }
      ]
    },
    {
      title: intl.formatMessage({ id: 'preferences.integrations.title' }),
      canView: true,
      icon: <IntegrationsIcon />,
      items: [
        {
          key: 'notifications', title: intl.formatMessage({ id: 'preferences.notifications.title' }), click: () => { click('notifications', <NotificationsPreferences onClose={ onClose } />); }, canView: true
        },
        {
          key: 'smtp', title: intl.formatMessage({ id: 'preferences.smtp.title' }), click: () => { click('smtp', <Smtp onClose={ onClose } />); }, canView: isSmtpEnabled
        }
      ]
    }
  ];

  return (
    <Wrapper>
      <Tabs>
        <Title>{intl.formatMessage({ id: 'preferences.title' })}</Title>
        <Content>
          { menuItems.filter((group) => group.canView).map((group) => (
            <TabGroup key={ group.title }>
              <Header>
                { group.icon ? <IconWrapper>{ group.icon }</IconWrapper> : null }
                <Subtitle hasIcon={ group.icon }>{group.title}</Subtitle>
              </Header>
              { group.items.filter((item) => item.canView).map((item) => (
                <Item key={ item.key } active={ active === item.key } name={ item.key } onClick={ item.click } hasIcon={ item.icon }>
                  { item.icon ? item.icon : null }
                  {' '}
                  {item.title}
                </Item>
              ))}
            </TabGroup>
          ))}
        </Content>
      </Tabs>
      <TabContent>
        {visibleComponent}
      </TabContent>
    </Wrapper>
  );
};

Menu.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default Menu;
