import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import {
  Wrapper, Title, dropdownStyle, List,
  DataItem, Item, TrashWrapper, Trash
} from './styled';
import { getRequesters, setRequester } from 'store/Settings/serviceDesk/actions';
import { selectRequestersOptions, selectRequestersAdded } from 'store/Settings/serviceDesk/selectors';
import { SEARCH_ENDPOINTS } from 'store/Settings/serviceDesk/constants';

const Requester = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const requesters = useSelector(selectRequestersOptions);
  const [requestersOptions, setRequesters] = useState(requesters);
  // const requestersOptions = ["admin1", "admin2", "admin3", "user4", "longerusernametestingsdfkjsdlfsadalsjd4a", "anotherone", "usernameadmin333"];
  const [selectedOption, setSelectedOption] = useState('');
  const options = requestersOptions.map((u) => ({ value: u, label: u }));
  const [showOptions, setShowOptions] = useState(false);
  const placeholder = intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.basicSetup.requester.placeholder' });
  const requesterAdded = useSelector(selectRequestersAdded);

  const handleInputChange = useCallback((typedOption) => {
    if (typedOption.length > 2) {
      dispatch(getRequesters(typedOption, SEARCH_ENDPOINTS.requester, setRequesters));
      setShowOptions(true);
    } else setShowOptions(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (v) => {
    setSelectedOption(v.value);
    dispatch(setRequester(v.value));
  };

  const removeRequester = () => {
    setSelectedOption('');
    dispatch(setRequester(''));
  };

  return (
    <Wrapper>
      <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.basicSetup.requester' }) }</Title>
      <Select
        value={ selectedOption }
        options={ showOptions ? options.filter((r) => r.label !== selectedOption) : [] }
        onChange={ handleChange }
        onInputChange = { handleInputChange }
        controlShouldRenderValue={ false }
        noOptionsMessage={ () => null }
        styles={ dropdownStyle }
        placeholder={ placeholder }
      />
      { requesterAdded
        ? <List>
            <DataItem
              key={ `di_${Math.random().toString(36).substr(2, 9)}` }
            >
              <Item>{requesterAdded}</Item>
              <TrashWrapper
                onClick={ removeRequester }
              >
                <Trash />
              </TrashWrapper>
            </DataItem>
          </List>
        : null
      }
    </Wrapper>
  );
};

export default Requester;
