/* eslint-disable react/no-children-prop */
import React, { useRef, useState } from 'react';
import { useResizeY } from 'Hooks/useResize/index';
import { PropTypes } from 'prop-types';
import {
  Wrapper, Title, ChildWrapper, Items,
  Header, ExpandedIcon, CollapsedIcon, HeaderChild,
  Button
} from './styled';

const ExpandableWrapper = ({
  children, title, canResize, expanded, headerChildren
}) => {
  const ref = useRef(null);
  const childRef = useRef(null);

  const [isExpanded, setIsExpanded] = useState(!!expanded);
  const handleClick = () => setIsExpanded(!isExpanded);

  const minHeight = (min) => Math.max(min - 97, 0);
  const defaultHeight = (def) => Math.max(def - 97, 0);
  const resizerHeight = useResizeY(childRef, ref, defaultHeight(350), minHeight(150));

  const height = canResize ? resizerHeight : 0;

  return (
    <Wrapper>
      <Header>
        <Items>
          <Title children={ title } />
          <HeaderChild children={ headerChildren } />
          {/* <HeaderChildren /> */}
        </Items>
        <Button>{ isExpanded ? <ExpandedIcon onClick={ handleClick } /> : <CollapsedIcon onClick={ handleClick } /> }</Button>
      </Header>
      <ChildWrapper ref={ childRef } containerHeight={ height } $isExpanded={ isExpanded } children={ children } />
    </Wrapper>
  );
};

ExpandableWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  canResize: PropTypes.bool
};

ExpandableWrapper.defaultProps = {
  title: '',
  canResize: false
};

export default ExpandableWrapper;
