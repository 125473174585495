import styled from 'styled-components';
import { ReactComponent as ServiceDesk } from 'Images/serviceDesk.svg';

export const Wrapper = styled.div`
  font-size: 21.5px;
  min-height: 100%;
  max-height: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 21.5px;
`;
Title.displayName = 'Title';

export const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 0;
  & > *:not(:last-child) {
    border-bottom: 1px;
    border-bottom-style: solid;
    border-bottom-color: #e9e9e9;
  }
`;
Content.displayName = 'Content';

export const ServiceDeskLogo = styled(ServiceDesk)`
  margin: 9px 0;
`;
ServiceDeskLogo.displayName = 'ServiceDeskLogo';
