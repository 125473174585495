/* eslint-disable react/jsx-key */
import React from 'react';
import get from 'lodash/get';
import Processing from './components/Processing';
import FinishedOk from './components/FinishedOk';
import FinishedWithError from './components/FinishedWithError';
import Uploading from './components/Uploading';

const Status = ({ file }) => {
  const mockCommandId = get(file, 'mockCommandId', '');
  const commandId = get(file, 'command_id', 0);
  const finished = get(file, 'finished', false);
  const error = get(file, 'error', true);
  const warning = get(file, 'warning', null);
  const warnings = get(file, 'warnings', null);
  const commandType = get(file, 'commandType', '');
  const assetsTotal = get(file, 'assetsTotal', 0);
  const destinationWsName = get(file, 'destinationWsName', 0);

  let numberSkipped = 0;
  let successfulMoved = 0;
  if (commandType === 'moveAssets') {
    numberSkipped = warning ? warning.split(' ')[0] : 0;
    successfulMoved = assetsTotal - numberSkipped;
  }

  const showFinishedWithError = error ||
  (warning && !commandType) ||
  (warning && commandType && numberSkipped && !successfulMoved) ||
  warnings;

  if (!finished && mockCommandId) return <Uploading />;
  if (!finished && commandId) return <Processing commandType={ commandType } />;
  if (showFinishedWithError) return <FinishedWithError file={ file } warning={ warning } warnings={ warnings } />;
  return <FinishedOk commandId={ commandId } commandType={ commandType } assetsTotal={ assetsTotal } warning={ warning } destinationWsName={ destinationWsName } />;
};

export default Status;
