import React, { useState, useEffect } from 'react';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContent } from './styled';
import {
  Header, Title, Subtitle,
  DocLink, Buttons, CancelButton, SaveButton,
  Content
} from '../BasicSetupModal/styled';
import { closeModal } from 'store/modals/actions';
import { MODAL_SERVICE_DESK_CONFIGURE_TEMPLATE } from 'store/modals/modals';
import OptionalFields from './components/OptionalFields';
import MandatoryFields from './components/MandatoryFields';
import { selectCanSaveTemplate, selectLoadingTemplate, selectMandatoryFieldsTemplate, selectOptionalFieldsTemplate, selectOptionalFieldsToAdd, selectTemplateSelectedFields } from 'store/Settings/serviceDesk/selectors';
import { resetTemplateSelected, saveTemplateConfig } from 'store/Settings/serviceDesk/actions';
import SpinnerComponent from 'Common/Components/SpinnerContainer';

const ConfigureTemplateModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const docLink = '';
  const mandatoryFields = useSelector(selectMandatoryFieldsTemplate);
  const optionalFields = useSelector(selectOptionalFieldsTemplate);
  const templateHasMandatoryFields = mandatoryFields?.length > 0;
  const templateHasOptionalFields = optionalFields?.length > 0;
  const canSaveTemplateInitial = useSelector(selectCanSaveTemplate);
  const [canSaveTemplate, setCanSaveTemplateInitial] = useState(canSaveTemplateInitial);
  const selectedTemplateFields = useSelector(selectTemplateSelectedFields);
  const optionalFieldsToAdd = useSelector(selectOptionalFieldsToAdd);
  const isLoading = useSelector(selectLoadingTemplate);

  useEffect(() => {
    setCanSaveTemplateInitial(canSaveTemplateInitial);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateFields, optionalFieldsToAdd]);

  const close = () => {
    dispatch(closeModal(MODAL_SERVICE_DESK_CONFIGURE_TEMPLATE));
    dispatch(resetTemplateSelected());
  };

  const save = () => {
    dispatch(saveTemplateConfig());
    close();
  };

  return (
    <ModalWrapper>
      <ModalContent>
        <Header>
          <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.template.title' }) }</Title>
          <Buttons>
            <CancelButton onClick={ close }>Cancel</CancelButton>
            <SaveButton onClick={ save } disabled={ !canSaveTemplate }>Save</SaveButton>
          </Buttons>
        </Header>

          <Content>
          { isLoading
            ? <SpinnerComponent height="100%" />
            : <>
                <Subtitle>
                  { intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.template.subtitle' }) }
                  <DocLink href={ docLink } target="_blank">{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.basicSetup.doc' }) }</DocLink>
                </Subtitle>
                { templateHasMandatoryFields ? <MandatoryFields /> : null }
                { templateHasOptionalFields ? <OptionalFields /> : null }
              </>
          }
          </Content>

      </ModalContent>
    </ModalWrapper>
  );
};

export default ConfigureTemplateModal;
