import React from 'react';
import { useIntl } from 'react-intl';
import {
  Wrapper, Header, Title, Line, Content,
  Row, NameCell, FieldsCell,
  Buttons, Delete, Edit,
  CustomRadio
} from './styled';
import { openModal } from 'store/modals/actions';
import { MODAL_SERVICE_DESK_CONFIGURE_TEMPLATE } from 'store/modals/modals';
import { useDispatch, useSelector } from 'react-redux';
import { selectMandatoryFieldsTemplate, selectTemplateActive, selectTemplatesAdded } from 'store/Settings/serviceDesk/selectors';
import { deleteTemplate, getTemplateDataById, activateTemplate, error } from 'store/Settings/serviceDesk/actions';

const Table = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const templateActive = useSelector(selectTemplateActive);
  const templatesAdded = useSelector(selectTemplatesAdded);
  const mockRows = [...Array(3).keys()];
  const mandatoryFields = useSelector(selectMandatoryFieldsTemplate);
  const templateHasMandatoryFields = mandatoryFields?.length > 0;

  const onDelete = (id, name) => {
    const filteredTemplates = templatesAdded?.filter((t) => t.id !== id);
    dispatch(deleteTemplate(filteredTemplates, name));
  };

  const onEdit = (id, name) => {
    dispatch(getTemplateDataById(id, name));
    dispatch(openModal(MODAL_SERVICE_DESK_CONFIGURE_TEMPLATE));
  };

  const getMandatoryFields = (template) => {
    if (template?.fields) {
      const filteredMandatory = template.fields.filter(el => { return Object.values(el)[0].type === 'mandatory'; });
      const keys = filteredMandatory.map(el => Object.keys(el)[0]);
      return keys?.join(', ');
    } else return null;
  };

  const handleActive = (template) => {
    if (templateHasMandatoryFields) {
      if (template?.fields?.length > 0) {
        dispatch(activateTemplate(template.name));
      } else {
        dispatch(error('Please configure the template before activating it'));
      }
    } else {
      dispatch(activateTemplate(template.name));
    }
  };

  return (
    <Wrapper>
      <Header>
        <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.templates.name' }) }</Title>
        <Line />
        <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.templates.mandatoryFields' }) }</Title>
      </Header>
      <Content>
        { templatesAdded?.length > 0
          ? templatesAdded?.map((template, i) => (
            <Row key={ `${template.name}-${i}` }>
              <CustomRadio checked={ templateActive === template.name } onChange={ () => handleActive(template) } />
              <NameCell>{ template.name }</NameCell>
              <FieldsCell>
                { getMandatoryFields(template) }
              </FieldsCell>
              <Buttons>
                <Delete title="Delete template" onClick={ () => onDelete(template.id, template.name) } />
                <Edit title="Edit template" onClick={ () => onEdit(template.id, template.name) } />
              </Buttons>
            </Row>
          ))
          : mockRows.map((r) => <Row key={ r } />)
        }
      </Content>
    </Wrapper>
  );
};

export default Table;
