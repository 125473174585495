import styled from 'styled-components';
import colors from 'Styles/colors';
import RadioButton from 'Common/Components/RadioButton/index';
import { Indicator } from 'Common/Components/RadioButton/styled';
import { ellipsisText } from 'Styles/styles';
import { ReactComponent as Delete_ } from 'Images/icon-action-bar-trash.svg';
import { ReactComponent as Edit_ } from 'Images/icon-action-bar-edit.svg';

export const Wrapper = styled.div`
  width: 612px;
  margin: 12px 0 15px;
`;
Wrapper.displayName = 'Wrapper';

export const Header = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  border-top: solid 1px ${colors.light3};
  border-bottom: solid 1px ${colors.light3};
  background-color: ${colors.white};
  & >:first-child {
    width: 255px;
    padding-left: 33px;
  }
  & >:last-child {
    width: 390px;
    padding-left: 14.5px;
  }
`;
Header.displayName = 'Header';

export const Title = styled.div`
  font-family: 'CentraNo2';
  font-size: 10px;
  color: ${colors.blueGrey};
  font-weight: 400;

`;
Title.displayName = 'Title';

export const Content = styled.div`
  background-color: ${colors.white};
  padding-bottom: 8px;
  height: 125px;
  overflow-y: scroll;
`;
Content.displayName = 'Content';

export const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: solid 1px ${colors.light3};
  height: 39px;
  padding-left: 10px;
  /* & >:nth-child(2) {
    padding-left: 5px;
  }
  & >:nth-child(3) {
    padding-left: 24.5px;
  } */
`;
Row.displayName = 'Row';

export const Col = styled.div`
  font-size: 11.5px;
  color: ${colors.darkBlueGrey};
  padding-left: 14.5px;
  text-transform: capitalize;
  ${ellipsisText}
`;
Col.displayName = 'Col';

export const CustomRadio = styled(RadioButton)`
  margin: 0px 0px 18px 0px;
  padding-left: 10px;

  ${Indicator} {
    width: 14px;
    height: 14px;
    background-color: #f7f7f7;
    top: 3px;
    &::after {
      width: 7px !important;
      height: 7px !important;
    }
  }
`;
CustomRadio.displayName = 'CustomRadio';

export const NameCell = styled(Col)`
  width: 215px;
  /* padding-left: 5px; */
`;
NameCell.displayName = 'NameCell';

export const FieldsCell = styled(Col)`
  width: 280px;
  padding-left: 25px;
`;
FieldsCell.displayName = 'FieldsCell';

export const Line = styled.div`
  height: 12px;
  border: 1px dashed ${colors.blueGrey};
  opacity: 0.7;
`;
Line.displayName = 'Line';

export const Buttons = styled.div`
  display: flex; 
  margin-left: 13px;
`;
Buttons.displayName = 'Buttons';

export const Delete = styled(Delete_)`
  height: 17px;
  margin-right: 13px;
  g > path {
    fill: ${colors.red1};
  }
`;
Delete.displayName = 'Delete';

export const Edit = styled(Edit_)`
  height: 17px;
  * > path {
    fill: ${colors.blueGrey};
  }
`;
Edit.displayName = 'Edit';
