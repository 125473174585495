/* eslint-disable react/jsx-key */
import React from 'react';
import { useDispatch } from 'react-redux';
import { Wrapper } from '../styled';
import { CustomCheckMark, StyledLabel, Delete } from './styled';
import { useIntl } from 'react-intl';
import { removeCommand } from 'Common/Components/FileUploadContext/actions';

const FinishedOk = ({ commandId, commandType, assetsTotal, warning, destinationWsName }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const message = () => {
    if (commandType === 'moveAssets') {
      const numberSkipped = warning ? warning.split(' ')[0] : 0;
      const successfulMoved = assetsTotal - numberSkipped;
      const successMessage = successfulMoved > 1 ? `${successfulMoved} assets were moved to ${destinationWsName}.` : `1 asset was moved to ${destinationWsName}.`;
      if (numberSkipped && successfulMoved > 0) return `${successMessage} ${warning}`;
      else if ((!numberSkipped && successfulMoved > 0)) return successMessage;
    } else return intl.formatMessage({ id: 'uploadContext.status.finishedOk' });
  };

  return (
    <Wrapper>
      <CustomCheckMark />
      <StyledLabel children={ message() } />
      <Delete onClick={ () => dispatch(removeCommand(commandId)) } />
    </Wrapper>
  );
};

export default FinishedOk;
