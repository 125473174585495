import styled from 'styled-components';

const Wrapper = styled.div`
  ${({ mandatory }) => mandatory && `
    margin: 15px 0;
  `}
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
