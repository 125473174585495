import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Wrapper, dropdownStyle, PlusIcon } from './styled';
import Select from 'react-select';
import { selectTemplatesAvailable, selectTemplatesAdded } from 'store/Settings/serviceDesk/selectors';
import { addTemplate } from 'store/Settings/serviceDesk/actions';
import isEmpty from 'lodash/isEmpty';

export const CustomIcon = () => {
  return (
    <PlusIcon />
  );
};

const TemplatesDropdown = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const templates = useSelector(selectTemplatesAvailable);
  const templatesAdded = useSelector(selectTemplatesAdded);
  // const templates = [{name:"Incident Report ksjdfsdbnfjbdsj", id: '1'}, {name:"dos", id: 2}, {name:"tres", id: 3}, {name:"cuatro"}, {name:"cinco"}, {name:"seis"}, {name:"siete"}];
  const templatesList = isEmpty(templatesAdded) ? templates.map((t) => ({ label: t.name, value: t })) : templates.filter(el => !templatesAdded.some(t => t.id === el.id)).map((t) => ({ label: t.name, value: t }));
  const [selectedOption, setSelectedOption] = useState('');
  const placeholder = intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.templates.dropdown' });

  const onSelectHandler = (item) => {
    setSelectedOption(item);
    if (isEmpty(templatesAdded)) dispatch(addTemplate([item.value], item.value?.name));
    else dispatch(addTemplate([...templatesAdded, item.value], item.value?.name));
  };

  return (
    <Wrapper>
      <Select
        value={ selectedOption }
        onChange={ onSelectHandler }
        isSearchable={ false }
        options={ templatesList }
        controlShouldRenderValue={ false }
        placeholder={ placeholder }
        styles={ dropdownStyle }
        components={ { DropdownIndicator: CustomIcon } }
        isDisabled={ isEmpty(templatesList) }
      />
    </Wrapper>
  );
};

export default TemplatesDropdown;
