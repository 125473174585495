export const SET_HOSTS_FILTER_ERROR = 'SET_HOSTS_FILTER_ERROR';
export const GET_DATA_HOST_START = 'GET_DATA_HOST_START';
export const GET_DATA_HOST_FAIL = 'GET_DATA_HOST_FAIL';
export const GET_DATA_HOST_SUCCESS = 'GET_DATA_HOST_SUCCESS';
export const UPDATE_DATA_HOST_LIST = 'UPDATE_DATA_HOST_LIST';
export const APPEND_DATA_HOST_LIST = 'APPEND_DATA_HOST_LIST';
export const TOGGLE_MODAL_EDIT_CREATE_HOST = 'TOGGLE_MODAL_EDIT_CREATE_HOST';
export const GET_DATA_HOST_GROUP_BY_RESET = 'GET_DATA_HOST_GROUP_BY_RESET';
export const GET_DATA_HOST_GROUP_BY_EXPAND = 'GET_DATA_HOST_GROUP_BY_EXPAND';
export const GET_DATA_HOST_SEARCH = 'GET_DATA_HOST_SEARCH';
export const HOST_SELECTED = 'HOST_SELECTED';
export const HOST_UNSELECTED = 'HOST_UNSELECTED';
export const RESET_STATE_HOST = 'RESET_STATE_HOST';
export const SHOW_MODAL_DELETE_HOSTS = 'SHOW_MODAL_DELETE_HOSTS';
export const HIDE_MODAL_DELETE_HOSTS = 'HIDE_MODAL_DELETE_HOSTS';
export const DELETE_HOSTS_START = 'DELETE_HOSTS_START';
export const DELETE_HOSTS_SUCCESS = 'DELETE_HOSTS_SUCCESS';
export const DELETE_HOSTS_FAIL = 'DELETE_HOSTS_FAIL';
export const GET_TOTAL_HOSTS = 'GET_TOTAL_HOSTS';
export const GET_HOSTS_LIMIT_REACHED = 'GET_HOSTS_LIMIT_REACHED';
export const SHOW_HOST_LEFT_FILTERS = 'SHOW_HOST_LEFT_FILTERS';

export const NEW_PIVOT = 'NEW_PIVOT';
export const SELECT_ALL_HOST = 'SELECT_ALL_HOST';
export const UNSELECT_ALL_HOST = 'UNSELECT_ALL_HOST';

export const UPDATE_HOST_SELECTED = 'UPDATE_HOST_SELECTED';
export const SET_TAGS_SUCCESS = 'SET_TAGS_SUCCESS';
export const SET_TAGS_FAIL = 'SET_TAGS_FAIL';

// CONTEXT
export const REMOVE_CURRENT_HOST = 'REMOVE_CURRENT_HOST';
export const SET_HOST_FOR_WORKING = 'SET_HOST_FOR_WORKING';
export const ASSETS_APPEND_DATA_HOST_LIST = 'ASSETS_APPEND_DATA_HOST_LIST';
export const ASSET_SELECTED = 'ASSET_SELECTED';
export const ASSET_UNSELECTED = 'ASSET_UNSELECTED';
export const ASSETS_SHOW_CONTEXT_MENU = 'ASSETS_SHOW_CONTEXT_MENU';
export const ASSETS_SET_BULK_UPDATE_FIELD = 'ASSETS_SET_BULK_UPDATE_FIELD';
export const ASSETS_SET_BULK_UPDATE_VALUE = 'ASSETS_SET_BULK_UPDATE_VALUE';
export const ASSETS_ADD_BULK_UPDATE_VALUE = 'ASSETS_ADD_BULK_UPDATE_VALUE';
export const ASSETS_REMOVE_BULK_UPDATE_VALUE = 'ASSETS_REMOVE_BULK_UPDATE_VALUE';
export const ASSETS_REFRESH_LIST = 'ASSETS_REFRESH_LIST';
export const ASSETS_CONFIRMATION_CHANGE_START = 'ASSETS_CONFIRMATION_CHANGE_START';
export const ASSETS_SET_BULK_UPDATE_SUCCESS = 'ASSETS_SET_BULK_UPDATE_SUCCESS';
export const ASSETS_BULK_UPDATE_FINISHED = 'ASSETS_BULK_UPDATE_FINISHED';
export const ASSETS_ERROR = 'ASSETS_ERROR';
export const SELECT_TOTAL_ASSETS = 'SELECT_TOTAL_ASSETS';
export const UNSELECT_TOTAL_ASSETS = 'UNSELECT_TOTAL_ASSETS';

export const MOVE_ASSETS_TO_WORKSPACE = 'MOVE_ASSETS_TO_WORKSPACE';
