/* eslint-disable react/jsx-key */
import React from 'react';
import { Wrapper } from '../styled';
import { StyledLabel, LoadingWrapper, LoadingIcon } from './styled';
import { useIntl } from 'react-intl';
import LoadingGif from 'Images/faraday_loadingbar.gif';

const Processing = ({ commandType }) => {
  const intl = useIntl();
  return (
    <Wrapper>
      { !commandType ? <StyledLabel children={ intl.formatMessage({ id: 'uploadContext.status.inProgress' }) } /> : null }
      <LoadingWrapper children={ <LoadingIcon src={ LoadingGif } /> } />
    </Wrapper>
  );
};

export default Processing;
