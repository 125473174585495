import styled, { keyframes } from 'styled-components';
import colors from 'Styles/colors';
import BlueButton from 'Common/Components/BlueButton';
import { ReactComponent as Fetch } from 'Images/regenerate.svg';

export const Button = styled(BlueButton)`
  width: 262px;
  margin-right: 23px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
`;
Button.displayName = 'Button';

export const FetchIcon = styled(Fetch)`
  margin: 0 9px 2px 0;
  g {
    fill: ${colors.white};
  }
`;
FetchIcon.displayName = 'FetchIcon';

export const Text = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.white};
`;
Text.displayName = 'Text';

const spin = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

export const Spinner = styled(FetchIcon)`
  animation: ${spin} 1.5s ease-in-out infinite;
`;
Spinner.displayName = 'Spinner';
