import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { DROPDOWN_ENDPOINTS } from './constants';

export const selectIsFetching = (state) => get(state, 'settings.ticketingTools.serviceDesk.isFetching', false);
export const selectError = (state) => get(state, 'settings.ticketingTools.serviceDesk.error', false);
export const selectErrorMessage = (state) => get(state, 'settings.ticketingTools.serviceDesk.errorMessage', false);
export const selectUrl = (state) => get(state, 'settings.ticketingTools.serviceDesk.url', '');
export const selectApiToken = (state) => get(state, 'settings.ticketingTools.serviceDesk.api_token', '');
export const selectIsAuthenticated = (state) => get(state, 'settings.ticketingTools.serviceDesk.successful_login', false);
export const selectRequestersAdded = (state) => get(state, 'settings.ticketingTools.serviceDesk.requester', []);
export const selectOptionsData = (state, field) => get(state, `settings.ticketingTools.serviceDesk.templateSelected.optionsData.${field}`, []);
export const selectRequestersOptions = (state) => get(state, 'settings.ticketingTools.serviceDesk.requesters', []);
export const selectHasSeverities = (state) => !isEmpty(selectOptionsData(state, 'priorities'));
export const selectHasStatuses = (state) => !isEmpty(selectOptionsData(state, 'statuses'));
export const selectIsFetchingTemplates = (state) => get(state, 'settings.ticketingTools.serviceDesk.isFetchingTemplates', false);
export const selectTemplatesAvailable = (state) => get(state, 'settings.ticketingTools.serviceDesk.templates', []);

// "template_list": {
//   "Incident Report - 1": [
//     {"request_type": {"name": "Cyber Incident", "type": "mandatory"}},
//    {"category": {"name": "Cybersecurity", "type": "optional"}}
// ]
// }

export const selectTemplatesAdded = (state) => get(state, 'settings.ticketingTools.serviceDesk.template_list', []);
export const selectTemplateActive = (state) => get(state, 'settings.ticketingTools.serviceDesk.active_template', '');

export const selectSeveritiesParsed = (state) => {
  const severities = [
    { name: 'unclassified', value: get(state, 'settings.ticketingTools.serviceDesk.severity_outgoing.unclassified', '') },
    { name: 'info', value: get(state, 'settings.ticketingTools.serviceDesk.severity_outgoing.info', '') },
    { name: 'low', value: get(state, 'settings.ticketingTools.serviceDesk.severity_outgoing.low', '') },
    { name: 'medium', value: get(state, 'settings.ticketingTools.serviceDesk.severity_outgoing.medium', '') },
    { name: 'high', value: get(state, 'settings.ticketingTools.serviceDesk.severity_outgoing.high', '') },
    { name: 'critical', value: get(state, 'settings.ticketingTools.serviceDesk.severity_outgoing.critical', '') }
  ];
  return severities;
};

export const selectStatusOutgoingParsed = (state) => {
  const status = [
    { name: 'open', value: get(state, 'settings.ticketingTools.serviceDesk.status_outgoing.open', '') },
    { name: 'closed', value: get(state, 'settings.ticketingTools.serviceDesk.status_outgoing.closed', '') },
    { name: 're-opened', value: get(state, 'settings.ticketingTools.serviceDesk.status_outgoing.re-opened', '') },
    { name: 'risk-accepted', value: get(state, 'settings.ticketingTools.serviceDesk.status_outgoing.risk-accepted', '') }
  ];
  return status;
};

export const selectTemplateSelected = (state) => get(state, 'settings.ticketingTools.serviceDesk.templateSelected', []);
export const selectTemplateSelectedFields = (state) => get(state, 'settings.ticketingTools.serviceDesk.templateSelected.fields', []);

export const selectTemplateFieldValue = (state, fieldName) => {
  const template = selectTemplateSelected(state);
  const field = template?.fields?.find(item => Object.hasOwn(item, fieldName));
  const fieldValue = field?.[fieldName]?.data?.name || field?.[fieldName]?.data?.raw;
  return fieldValue;
};

export const selectStatusOutgoing = (state) => get(state, 'settings.ticketingTools.serviceDesk.status_outgoing', '');
export const selectStatusIncoming = (state) => get(state, 'settings.ticketingTools.serviceDesk.status_incoming', '');
export const selectSeveritiesOutgoing = (state) => get(state, 'settings.ticketingTools.serviceDesk.severity_outgoing', '');
export const selectBasicSetupFilled = (state) => {
  const requester = selectRequestersAdded(state);
  const severityOutgoing = selectSeveritiesOutgoing(state);
  const mappedIncoming = selectOptionsData(state, 'statuses');
  const statusIncoming = selectStatusIncoming(state, 'statuses');
  const statusOutgoing = selectStatusOutgoing(state);

  const basicSetupFilled = !isEmpty(requester) &&
    (!isEmpty(severityOutgoing) ? Object.keys(severityOutgoing).length === 6 : false) &&
    (!isEmpty(statusOutgoing) ? Object.keys(statusOutgoing).length === 4 : false) &&
    (!isEmpty(statusIncoming) ? Object.keys(statusIncoming).length === mappedIncoming?.length : false);

  return basicSetupFilled;
};
export const selectTemplateToEdit = (state) => get(state, 'settings.ticketingTools.serviceDesk.templateToEdit', {});
export const selectMandatoryFieldsTemplate = (state) => get(state, 'settings.ticketingTools.serviceDesk.templateToEdit.fields.mandatory', []);
export const selectOptionalFieldsTemplate = (state) => get(state, 'settings.ticketingTools.serviceDesk.templateToEdit.fields.optional', []);
export const selectOptionalFieldsToAdd = (state) => get(state, 'settings.ticketingTools.serviceDesk.optionalFieldsToAdd', []);
export const selectIsBasicSetupConfig = (state) => get(state, 'settings.ticketingTools.serviceDesk.basic_setup_configured', []);

export const selectCanSaveTemplate = (state) => {
  const mandatoryFields = selectMandatoryFieldsTemplate(state);
  const optionalFields = selectOptionalFieldsTemplate(state);
  const templateHasOptionalFields = optionalFields?.length > 0;
  const templateHasMandatoryFields = mandatoryFields?.length > 0;
  const templateToEdit = selectTemplateToEdit(state);
  const templateToEditFields = templateToEdit?.fields;
  const templateSelected = selectTemplateSelected(state);
  const optionalFieldsToAdd = selectOptionalFieldsToAdd(state);
  if (templateHasMandatoryFields && !templateHasOptionalFields) {
    const areAllMandatoryFieldsAdded = templateToEditFields?.mandatory.every((field) => {
      return templateSelected?.fields?.some((obj) => Object.hasOwn(obj, field.name) && (obj[field.name]?.data?.name || obj[field.name]?.data?.raw));
    });
    return areAllMandatoryFieldsAdded;
  } else if (templateHasMandatoryFields && templateHasOptionalFields) {
    const areAllMandatoryFieldsAdded = templateToEditFields?.mandatory.every((field) => {
      return templateSelected?.fields?.some((obj) => Object.hasOwn(obj, field.name) && (obj[field.name]?.data?.name || obj[field.name]?.data?.raw));
    });
    const optionalFieldsAddedHasData = optionalFieldsToAdd?.every((field) => {
      return templateSelected?.fields?.some((obj) => Object.hasOwn(obj, field.name) && (obj[field.name]?.data?.name || obj[field.name]?.data?.raw));
    });
    return areAllMandatoryFieldsAdded && optionalFieldsAddedHasData;
  } else if (!templateHasMandatoryFields && templateHasOptionalFields) {
    const optionalFieldsAddedHasData = optionalFieldsToAdd?.every((field) => {
      return templateSelected?.fields?.some((obj) => Object.hasOwn(obj, field.name) && (obj[field.name]?.data?.name || obj[field.name]?.data?.raw));
    });
    return optionalFieldsAddedHasData;
  } else return true;
};

export const selectLoadingTemplate = (state) => get(state, 'settings.ticketingTools.serviceDesk.isFetchingTemplateData', false);

export const selectCategoryId = (state) => {
  const category = selectTemplateFieldValue(state, 'category');
  const categories = selectOptionsData(state, DROPDOWN_ENDPOINTS.category);
  const categoryId = categories?.find((c) => c.data === category)?.id;
  return categoryId;
};

export const selectLoadingGroupedData = (state) => get(state, 'settings.ticketingTools.serviceDesk.templateSelected.isFetchingGroupedData', false);
