import styled from 'styled-components';
import { slideIn } from 'Styles/effects';
import colors from 'Styles/colors';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0 0 34px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  border-top: 10px solid ${colors.softBlue};
  background: #fff;
  width: 505px;
  user-select: none;
  height: 95%;
  ${slideIn}
`;
ModalContent.displayName = 'ModalContent';

export const SectionTitle = styled.div`
  font-size: 14.5px;
  font-weight: 400;
  color: ${colors.darkBlueGrey};
  margin-top: 20px;
`;
SectionTitle.displayName = 'SectionTitle';
