import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_HOSTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { useIntl } from 'react-intl';
import { openModal } from 'store/modals/actions';
import { MODAL_MOVE_ASSET_WS } from 'store/modals/modals';
import IconButton from 'Common/Components/IconButton';
import { ReactComponent as MoveIcon } from 'Images/moveAsset.svg';
import ModalWarning from 'Common/Components/ModalWarning';

const MoveAssetsButton = ({ hasHosts, hostsSelected, selectAll }) => {
  const dispatch = useDispatch();
  const allowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_HOSTS, PERMISSION_TYPES.UPDATE));
  const [showWarning, setShowWarning] = useState(false);
  const canUpdate = allowedByRole && hasHosts && !selectAll;
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'assets' });

  const handleClick = () => {
    if (canUpdate) {
      if (hostsSelected.length > 0) dispatch(openModal(MODAL_MOVE_ASSET_WS));
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <MoveIcon /> } title="Move to Workspace" onClick={ handleClick } disabled={ !allowedByRole || !hasHosts } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Move Asset(s)" description={ message } /> }
    </>
  );
};

export default MoveAssetsButton;
