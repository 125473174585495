import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { fetchActiveConfig, updateActiveConfigNew } from 'store/Settings/settingsActions';
import { Wrapper, ServiceDeskLogo } from './styled';
import { selectActiveConfig } from 'store/Settings/settingsSelectors';
import Header from '../../../Header';
import { StatusLabel, Title, Toolbar } from 'Screens/Contextualization/Settings/common-styled';
import Checkbox from 'Common/Components/Checkbox/styled';
import MainSettings from './components/MainSettings';
import { error, getServiceDeskSettings, saveTicketConfig } from 'store/Settings/serviceDesk/actions';
import { selectIsBasicSetupConfig, selectIsAuthenticated, selectTemplateActive } from 'store/Settings/serviceDesk/selectors';
import UpgradeLicense from '../UpgradeLicense';
import { FEATURE_TICKETING_SERVICE_DESK, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByFeature } from 'Hooks/usePermission/selector';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import upgradeLicenseImage from 'Images/ticketing-license.png';
import { ACTIONS } from 'tracking/GA/constants';

const ServiceDesk = ({ onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const configActive = useSelector(selectActiveConfig);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isBasicSetupConfigured = useSelector(selectIsBasicSetupConfig);
  const isTemplateActive = useSelector(selectTemplateActive);
  const canActivate = isAuthenticated && isBasicSetupConfigured && isTemplateActive;
  const isEnabledServiceDesk = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_SERVICE_DESK, PERMISSION_TYPES.CREATE));

  // obtengo los datos del servidor
  useEffect(() => {
    if (isEnabledServiceDesk) {
      dispatch(getServiceDeskSettings());
      dispatch(fetchActiveConfig());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onChange = () => {
    if (canActivate) dispatch(updateActiveConfigNew('serviceDesk'));
    else dispatch(error('Please fill all required fields before activating this integration'));
  };

  const onAccept = () => {
    if (isEnabledServiceDesk) {
      dispatch(saveTicketConfig());
      onClose();
    }
  };

  return (
    <Wrapper>
      <Header onAccept={ onAccept } onClose={ onClose } />
      { isEnabledServiceDesk
        ? <>
          <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.title' }) }</Title>
          <Toolbar>
            <ServiceDeskLogo />
            <StatusLabel isActive={ configActive.mesd?.active }>{ configActive.mesd?.active ? 'Active' : 'Inactive' }</StatusLabel>
            <Checkbox onChange={ onChange } checked={ configActive.mesd?.active || '' } />
          </Toolbar>
          <MainSettings />
          </>
        : <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.ticketing.description" trackingShow={ ACTIONS.showSettings } trackingClick={ ACTIONS.clickSettings } />
      }

    </Wrapper>
  );
};

export default ServiceDesk;
