import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as Close } from 'Images/icon_close_error.svg';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  display: flex;
  background-color: rgb(234 49 88 / 10%);
  border-radius: 3px;
  align-items: center;
  padding: 11px 21px;
`;
Content.displayName = 'Content';

export const Text = styled.div`
  font-size: 12.5px;
  color: ${colors.redPink};
  margin-right: 10px;
`;
Text.displayName = 'Text';

export const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  height: 14px;
  display: flex;
`;
CloseButton.displayName = 'CloseButton';

export const CloseIcon = styled(Close)`
  
`;
CloseIcon.displayName = 'CloseIcon';
