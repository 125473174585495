/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'Screens/Contextualization/Host/components/Table';
import ActionBar from './components/ActionBar';
import {
  bulkUpdateAssets, getData,
  addTag, removeTag, setAssetsTags
} from 'store/Host/actions';
import {
  hideHostDetail,
  showHostDetailById
} from 'store/Contextualization/AssetDetail/actions';
import { connect } from 'react-redux';
import HostDetail from 'Screens/Contextualization/Host/components/HostDetail';
import HostsLimitReached from 'Common/Components/HostsLimitReached';
import {
  selectHostCount, selectHostsSelected, selectIsFetching, selectModalBulkUpdateValue,
  selectSelectAllHosts, selectShowHostLeftFilters, selecHostsTags
} from 'store/Host/selectors';
import get from 'lodash/get';
import FilterSideBar from 'Screens/Contextualization/Manage/components/FilterSideBar';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_UPGRADE_LICENSE, MODAL_MOVE_ASSET_WS } from 'store/modals/modals';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import upgradeLicenseImage from 'Images/workspaces-license.png';
import { ACTIONS } from 'tracking/GA/constants';
import { Wrapper, TableContainer } from './styled';
import { getTags, getHostsTags, getServicesTags } from 'Screens/Contextualization/Tags/actions/Actions';
import { selectShowTagsModal } from 'store/Tags/selectors';
import CreateEditTagsModal from './components/CreateEditTagsModal';
import { getVulnsCountInWs } from 'Screens/Contextualization/Faraday/actions/Actions';
import { selectPathname } from 'store/Router/selectors';
import AssetsContextMenu from './components/Table/ContextMenu';
import BulkUpdateModal from './components/Table/components/BulkUpdateModal';
import BulkUpdateConfirmationDialog from '../Manage/components/ManageTable/components/BulkUpdateConfirmationDialog';
import SelectAll from './components/SelectAll';
import selectModal from 'store/modals/selectors';
import MoveAssetModal from './components/MoveAssetModal';

class HostContext extends Component {
  constructor (props) {
    super(props);
    this.actionBar = React.createRef();
    this.getTableContainerHeight = this.getTableContainerHeight.bind(this);
  }

  handleParamId () {
    const { pathname } = this.props;
    const id = get(this, 'props.params.id', 0);
    const currentWorkspace = get(this, 'props.params.ws', '');
    const isUrlInHostDetail = pathname === `/host/${currentWorkspace}/${id}`;
    if (isUrlInHostDetail && parseInt(id, 10) > 0) this.props.showHostDetailById(id);
    else this.props.hideHostDetail();
  }

  componentDidMount () {
    const { getTags, getHostsTags, getServicesTags } = this.props;
    this.handleParamId();
    getTags();
    getHostsTags();
    getServicesTags();
  }

  componentWillUnmount () {
    this.handleParamId();
  }

  componentDidUpdate (prevProps) {
    const { pathname } = this.props;
    if (pathname !== prevProps.location.pathname) {
      this.handleParamId();
      this.props.getVulnsCount();
    }
  }

  getTableContainerHeight () {
    const actionBarHeight = get(this.actionBar, 'current.clientHeight', 34);
    const tableContainerTopMargin = 0;
    return `calc(100% - ${actionBarHeight + tableContainerTopMargin}px)`;
  }

  render () {
    const {
      showLeftFilters, showCreateTagModal, hostsTags,
      hostsSelected, count, isFetching, bulkUpdateValue, selectAll,
      addTag, removeTag, setTags, showMoveAssetModal
    } = this.props;

    return (
      <Wrapper>
        <div ref={ this.actionBar }>
          <ActionBar />
        </div>
        <SelectAll />
        <TableContainer height={ this.getTableContainerHeight() }>
          <Table showLeftFilters={ showLeftFilters } />
          <FilterSideBar entity="assets" />
          <HostDetail hostTags={ hostsTags } />
          <AssetsContextMenu />
          <BulkUpdateModal />
          <BulkUpdateConfirmationDialog
            selected={ hostsSelected }
            count={ count }
            isSelectAll={ selectAll }
            isFetching={ isFetching }
            bulkUpdateValue={ bulkUpdateValue }
            bulkUpdateAction={ bulkUpdateAssets }
            title="Assets"
          />
        </TableContainer>
        <HostsLimitReached />
        <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
          <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.workspace.description" trackingShow={ ACTIONS.showAsset } trackingClick={ ACTIONS.clickAsset } />
        </ReduxModal>
        { showCreateTagModal &&
          <CreateEditTagsModal
            hostsTags={ hostsTags }
            entity="hosts"
            addTag={ addTag }
            removeTag={ removeTag }
            setTags={ setTags }
            hostsSelected={ hostsSelected }
          />
        }
        { showMoveAssetModal ? <MoveAssetModal /> : null }
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  showLeftFilters: selectShowHostLeftFilters(state),
  showCreateTagModal: selectShowTagsModal('hosts', state),
  hostsTags: selecHostsTags(state),
  pathname: selectPathname(state),
  hostsSelected: selectHostsSelected(state),
  count: selectHostCount(state),
  isFetching: selectIsFetching(state),
  bulkUpdateValue: selectModalBulkUpdateValue(state),
  selectAll: selectSelectAllHosts(state),
  showMoveAssetModal: selectModal(state, MODAL_MOVE_ASSET_WS)
});

const mapDispatchToProps = (dispatch) => ({
  showHostDetailById: (host) => {
    dispatch(showHostDetailById(host));
  },
  hideHostDetail: () => {
    dispatch(hideHostDetail());
  },
  getData: () => {
    dispatch(getData());
  },
  getTags: () => {
    dispatch(getTags());
  },
  getHostsTags: () => {
    dispatch(getHostsTags());
  },
  getVulnsCount: () => {
    dispatch(getVulnsCountInWs());
  },
  addTag: (tag) => {
    dispatch(addTag(tag));
  },
  removeTag: (tag) => {
    dispatch(removeTag(tag));
  },
  setTags: (tags) => {
    dispatch(setAssetsTags(tags));
  },
  getServicesTags: () => {
    dispatch(getServicesTags());
  }

});

HostContext.propTypes = {
  showHostDetailById: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(HostContext);
