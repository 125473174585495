import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import {
  selectError, selectErrorMessage, selectShowDetail,
  selectServiceDetail, selectServiceDetailId,
  selectServiceDetailOwned, selectServiceDetailDescription,
  selectServicesTags, selectServiceDetailTags, selectServicesSelected
} from 'store/Services/selectors';
import {
  clearError, hideServiceDetail,
  loadServiceDetail, resetServiceDetail,
  updateService, redirectToService, addTag, removeTag,
  setServicesTags
} from 'store/Services/actions';
import ErrorDialog from 'Common/Components/ErrorDialog';
import ActionBar from './components/ActionBar';
import ServicesTable from './components/ServicesTable';
import ServiceDetail from './components/ServiceDetail';
import { closeModal } from 'store/modals/actions';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_CANT_CREATE, MODAL_CREATE_SERVICE } from 'store/modals/modals';
import CreateService from './components/CreateService';
import Wrapper from './styled';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { getHostsTags, getServicesTags, getTags } from 'Screens/Contextualization/Tags/actions/Actions';
import { selectShowTagsModal } from 'store/Tags/selectors';
import CreateEditTagsModal from './components/CreateEditTagsModal';
import { getHostsCountInWs, getVulnsCountInWs } from 'Screens/Contextualization/Faraday/actions/Actions';
import selectModal from 'store/modals/selectors';
import WarningModal from 'Screens/Contextualization/ManageEditCreate/components/WarningModal';
import SelectAll from './components/ServicesTable/components/SelectAll';

const Services = ({ params }) => {
  const dispatch = useDispatch();
  const workspace = useSelector(selectCurrentWorkspace);
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);
  const showDetail = useSelector(selectShowDetail);
  const data = useSelector(selectServiceDetail);
  const serviceId = useSelector(selectServiceDetailId);
  const owned = useSelector(selectServiceDetailOwned);
  const description = useSelector(selectServiceDetailDescription);
  const servicesTags = useSelector(selectServicesTags);
  const showTagsModal = useSelector((state) => selectShowTagsModal('services', state));
  const serviceDetailTags = useSelector(selectServiceDetailTags);
  const servicesSelected = useSelector(selectServicesSelected);
  const showModalCantCreate = useSelector((state) => selectModal(state, MODAL_CANT_CREATE));

  const handleParamId = useCallback(() => {
    const id = get(params, 'id', 0);
    if (parseInt(id, 10) > 0) dispatch(loadServiceDetail(id));
    else dispatch(hideServiceDetail());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    dispatch(getTags());
    dispatch(getHostsTags());
    dispatch(getServicesTags());
    dispatch(getVulnsCountInWs());
    dispatch(getHostsCountInWs());
  }, [dispatch, workspace]);

  useEffect(() => {
    handleParamId();
  }, [handleParamId]);

  useEffect(() => () => {
    dispatch(closeModal(MODAL_CANT_CREATE));
  }, [dispatch]);

  return (
    <Wrapper>
      <ActionBar />
      <SelectAll />
      <ServicesTable />
      { showDetail &&
        <ServiceDetail
          data={ data }
          resetServiceDetail={ resetServiceDetail }
          serviceId={ serviceId }
          owned={ owned }
          updateService={ (id, obj) => dispatch(updateService(id, obj)) }
          redirectToService={ redirectToService }
          loadServiceDetail={ loadServiceDetail }
          description={ description }
          entity="serviceDetail"
          serviceTags={ serviceDetailTags }
          servicesSelected={ servicesSelected }
          addTag={ addTag }
          removeTag={ removeTag }
          setServicesTags={ setServicesTags }
        />
      }
      <ReduxModal id={ MODAL_CREATE_SERVICE }>
        <CreateService onClose={ () => dispatch(closeModal(MODAL_CREATE_SERVICE)) } entity="services" />
      </ReduxModal>
      { error ? <ErrorDialog message={ errorMessage } resetError={ () => dispatch(clearError()) } /> : null }
      { showTagsModal && <CreateEditTagsModal servicesTags={ servicesTags } entity="services" servicesSelected={ servicesSelected } addTag={ addTag } removeTag={ removeTag } setServicesTags={ setServicesTags } /> }
      { showModalCantCreate && <WarningModal entity="Service" /> }
    </Wrapper>
  );
};

export default Services;
