import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as ArrowRight_ } from 'Images/outgoing.svg';
import { ReactComponent as ArrowLeft_ } from 'Images/incoming.svg';

export const Wrapper = styled.div`
  padding: 0 11px 21px 11px;
`;
Wrapper.displayName = 'Wrapper';

export const Header = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  border-top: solid 1px ${colors.light3};
  border-bottom: solid 1px ${colors.light3};
  background-color: ${colors.white};
  & >:first-child {
    width: 330px;
  }
`;
Header.displayName = 'Header';

export const Title = styled.div`
  width: 150px;
  font-family: 'CentraNo2';
  font-size: 10px;
  color: ${colors.blueGrey};
  font-weight: 400;
  padding-left: 14.5px;
`;
Title.displayName = 'Title';

export const Content = styled.div`
  background-color: ${colors.white};
  padding-bottom: 8px;
`;
Content.displayName = 'Content';

export const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: solid 1px ${colors.light3};
  height: 39px;
  & >:last-child {
    padding: 0 9px 0 7px;
  }
`;
Row.displayName = 'Row';

export const Col = styled.div`
  width: 165px;
  font-size: 11.5px;
  color: ${colors.darkBlueGrey};
  padding-left: 14.5px;
  text-transform: capitalize;
`;
Col.displayName = 'Col';

export const Line = styled.div`
  height: 12px;
  border: 1px dashed ${colors.blueGrey};
`;
Line.displayName = 'Line';

export const CustomCol = styled(Col)`
  width: 182px;
`;
CustomCol.displayName = 'CustomCol';

export const CustomRow = styled(Row)`
  & >:last-child {
    padding-left: 10px;
  }
`;
CustomRow.displayName = 'CustomRow';

export const Tag = styled.div`
  width: 120px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
Tag.displayName = 'Tag';

export const ArrowRight = styled(ArrowRight_)`
  cursor: default;
`;
ArrowRight.displayName = 'ArrowRight';

export const ArrowLeft = styled(ArrowLeft_)`
  cursor: default;
`;
ArrowLeft.displayName = 'ArrowLeft';

export const TabWrapper = styled.div`
  display: flex;
`;
TabWrapper.displayName = 'TabWrapper';

export const Tab = styled.div`
  padding: 9px 0px 11px 0px;
  width: 119px;
  background-color: ${({ active }) => (active ? colors.white : '#e5e5e5')};
  color: ${colors.darkBlueGrey};
  font-size: 11.5px;
  font-weight: 400;
  cursor: pointer;
  text-align: center;
`;
Tab.displayName = 'Tab';

export const dropdownStyle = {
  control: () => ({
    display: 'flex',
    height: '28px',
    borderRadius: '1px',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 #afafaf',
    backgroundColor: '#ffffff',
    border: 'solid 1px #fafbfc',
    borderBottomWidth: '0px',
    color: 'white',
    paddingLeft: '10px',
    fontWeight: '700',
    textOverflow: 'ellipsis',
    cursor: 'pointer'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '13px',
    color: '#1c2243',
    opacity: 0.7,
    fontWeight: 300
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '13px',
    color: '#1c2243',
    fontWeight: 300
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#505965',
    width: '30px'
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: isFocused ? '#edf2f7' : 'white',
    fontSize: '12px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: isFocused ? 'pointer' : 'default',
    paddingLeft: '22px',
    paddingRight: '22px',
    height: '28px',
    textTransform: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: '1.2'
  }),
  valueContainer: () => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginRight: 'auto',
    cursor: 'pointer'
  }),
  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '129px'
  }),
  menuList: () => ({
    maxHeight: '150px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '10px',
    paddingBottom: '13px'
  }),
  singleValue: (provided) => {
    const transition = 'opacity 300ms';
    return {
      ...provided, transition, color: '#1c2243', minWidth: '100px', height: '16px', fontSize: '11.5px', fontWeight: 400, top: '8px', position: 'relative'
    };
  }
};
