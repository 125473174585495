import React from 'react';
import { useIntl } from 'react-intl';
import FetchButton from './components/FetchButton';
import Table from './components/Table';
import { Wrapper, Row, Title } from './styled';
import TemplatesDropdown from './components/TemplatesDropwdown';

const Templates = () => {
  const intl = useIntl();

  return (
    <Wrapper>
      <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.templates' }) }</Title>
      <Row>
        <FetchButton />
        <TemplatesDropdown />
      </Row>
      <Table />
    </Wrapper>
  );
};

export default Templates;
