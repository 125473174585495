function createLink (type, ws_name, id) {
  switch (type) {
    case 'agent':
      return '/automation/agents';
    case 'schedule':
      return '/automation/schedule';
    case 'users':
      return '/users';
    case 'vulnerability':
      return `/manage/${ws_name}${id ? `/${id}` : ''}`;
    case 'host':
      return `/host/${ws_name}${id ? `/${id}` : ''}`;
    case 'workspaces':
      return '/workspaces';
    case 'report':
      return `/report/${ws_name}`;
    case 'feed':
      return `/feed/${ws_name}`;
    case 'pipeline':
      return '/automation/pipelines';
    case 'planner':
      return '/planner';
    case 'command':
      return `/manage/${ws_name}`;
    case 'command_assets':
      return `/host/${ws_name}`;
    default:
      return '/404';
  }
}

export default createLink;
