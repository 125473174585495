export const SEARCH_ENDPOINTS = {
  requester: 'users',
  technician: 'technicians',
  site: 'sites'
};

export const DROPDOWN_ENDPOINTS = {
  item: 'items',
  category: 'categories',
  support_group: 'support_groups',
  urgency: 'urgencies',
  level: 'levels',
  mode: 'modes',
  impact: 'impacts',
  request_type: 'request_types',
  priority: 'priorities',
  status: 'statuses',
  group: 'group'
};
