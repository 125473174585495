export const SETTINGS_SERVICE_DESK_REQUEST = 'SETTINGS_SERVICE_DESK_REQUEST';
export const SETTINGS_SERVICE_DESK_SUCCESS = 'SETTINGS_SERVICE_DESK_SUCCESS';
export const SETTINGS_SERVICE_DESK_SET_FIELD = 'SETTINGS_SERVICE_DESK_SET_FIELD';
export const SETTINGS_SERVICE_DESK_RESET = 'SETTINGS_SERVICE_DESK_RESET';
export const SETTINGS_SERVICE_DESK_FAILURE = 'SETTINGS_SERVICE_DESK_FAILURE';
export const SETTINGS_SERVICE_DESK_AUTH_SUCCESS = 'SETTINGS_SERVICE_DESK_AUTH_SUCCESS';
export const SETTINGS_SERVICE_DESK_AUTH_FAILURE = 'SETTINGS_SERVICE_DESK_AUTH_FAILURE';
export const SETTINGS_SERVICE_DESK_GET_REQUESTERS_SUCCESS = 'SETTINGS_SERVICE_DESK_GET_REQUESTERS_SUCCESS';
export const SETTINGS_SERVICE_DESK_GET_DROPDOWN_DATA_SUCCESS = 'SETTINGS_SERVICE_DESK_GET_DROPDOWN_DATA_SUCCESS';
export const SETTINGS_SERVICE_DESK_SET_REQUESTER = 'SETTINGS_SERVICE_DESK_SET_REQUESTER';
export const SETTINGS_SERVICE_DESK_SET_VALUE_SEARCH = 'SETTINGS_SERVICE_DESK_SET_VALUE_SEARCH';
export const SETTINGS_SERVICE_DESK_REMOVE_VALUE_SEARCH = 'SETTINGS_SERVICE_DESK_REMOVE_VALUE_SEARCH';
export const SETTINGS_SERVICE_DESK_TEMPLATES_REQUEST = 'SETTINGS_SERVICE_DESK_TEMPLATES_REQUEST';
export const SETTINGS_SERVICE_DESK_TEMPLATES_FAILURE = 'SETTINGS_SERVICE_DESK_TEMPLATES_FAILURE';
export const SETTINGS_SERVICE_DESK_TEMPLATES_SUCCESS = 'SETTINGS_SERVICE_DESK_TEMPLATES_SUCCESS';
export const SETTINGS_SERVICE_DESK_TEMPLATE_DATA_SUCCESS = 'SETTINGS_SERVICE_DESK_TEMPLATE_DATA_SUCCESS';
export const SETTINGS_SERVICE_DESK_SET_SEVERITIES_TYPE = 'SETTINGS_SERVICE_DESK_SET_SEVERITIES_TYPE';
export const SETTINGS_SERVICE_DESK_SET_OUTGOING_STATUS = 'SETTINGS_SERVICE_DESK_SET_OUTGOING_STATUS';
export const SETTINGS_SERVICE_DESK_SET_INCOMING_STATUS = 'SETTINGS_SERVICE_DESK_SET_INCOMING_STATUS';
export const SETTINGS_SERVICE_DESK_BASIC_SETUP_SUCCESS = 'SETTINGS_SERVICE_DESK_BASIC_SETUP_SUCCESS';
export const SETTINGS_SERVICE_DESK_BASIC_SETUP_FAILURE = 'SETTINGS_SERVICE_DESK_BASIC_SETUP_FAILURE';
export const SETTINGS_SERVICE_DESK_SAVE_TEMPLATES_SUCCESS = 'SETTINGS_SERVICE_DESK_SAVE_TEMPLATES_SUCCESS';
export const SETTINGS_SERVICE_DESK_SAVE_TEMPLATES_FAILURE = 'SETTINGS_SERVICE_DESK_SAVE_TEMPLATES_FAILURE';
export const GET_SETTINGS_TICKETING_TOOLS_SERVICE_DESK_START = 'GET_SETTINGS_TICKETING_TOOLS_SERVICE_DESK_START';
export const GET_SETTINGS_TICKETING_TOOLS_SERVICE_DESK_SUCCESS = 'GET_SETTINGS_TICKETING_TOOLS_SERVICE_DESK_SUCCESS';
export const GET_SETTINGS_TICKETING_TOOLS_SERVICE_DESK_FAIL = 'GET_SETTINGS_TICKETING_TOOLS_SERVICE_DESK_FAIL';
export const SETTINGS_SERVICE_DESK_ACTIVATE_TEMPLATE_SUCCESS = 'SETTINGS_SERVICE_DESK_ACTIVATE_TEMPLATE_SUCCESS';
export const SETTINGS_SERVICE_DESK_ACTIVATE_TEMPLATE_FAILURE = 'SETTINGS_SERVICE_DESK_ACTIVATE_TEMPLATE_FAILURE';
export const SETTINGS_SERVICE_DESK_DELETE_TEMPLATE_SUCCESS = 'SETTINGS_SERVICE_DESK_DELETE_TEMPLATE_SUCCESS';
export const SETTINGS_SERVICE_DESK_DELETE_TEMPLATE_FAILURE = 'SETTINGS_SERVICE_DESK_DELETE_TEMPLATE_FAILURE';
export const SETTINGS_SERVICE_DESK_DEAUTHENTICATED = 'SETTINGS_SERVICE_DESK_DEAUTHENTICATED';
export const TEMPLATE_SELECTED_SD = 'TEMPLATE_SELECTED_SD';
export const SETTINGS_SERVICE_DESK_UPDATE_TEMPLATE_SELECTED = 'SETTINGS_SERVICE_DESK_UPDATE_TEMPLATE_SELECTED';
export const SETTINGS_SERVICE_DESK_RESET_TEMPLATE = 'SETTINGS_SERVICE_DESK_RESET_TEMPLATE';
export const SETTINGS_SERVICE_DESK_TEMPLATE_DATA_REQUEST = 'SETTINGS_SERVICE_DESK_TEMPLATE_DATA_REQUEST';
export const SETTINGS_SERVICE_DESK_GET_SEARCH_DATA_SUCCESS = 'SETTINGS_SERVICE_DESK_GET_SEARCH_DATA_SUCCESS';
export const SETTINGS_SERVICE_DESK_GET_GROUPED_DROPDOWN_DATA_REQUEST = 'SETTINGS_SERVICE_DESK_GET_GROUPED_DROPDOWN_DATA_REQUEST';
export const SETTINGS_SERVICE_DESK_GET_GROUPED_DROPDOWN_DATA_SUCCESS = 'SETTINGS_SERVICE_DESK_GET_GROUPED_DROPDOWN_DATA_SUCCESS';
