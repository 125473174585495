import React from 'react';
import { Title, Table, Value, Row } from './styled';

const DetailsSection = ({ cvss4, details, macroVector }) => {
  const vectorString = cvss4?.vector_string;
  const { complexity, exploitability, exploitation, security_requirements, subsequent_system, vulnerable_system } = details;

  return (
    <Table>
      <Row>
        <Title>Macro Vector</Title>
        <Value>{ vectorString ? macroVector : 'N/A' }</Value>
      </Row>
      <Row>
        <Title>Exploitability</Title>
        <Value>{ vectorString ? exploitability : 'N/A' }</Value>
      </Row>
      <Row>
        <Title>Complexity</Title>
        <Value>{ vectorString ? complexity : 'N/A' }</Value>
      </Row>
      <Row>
        <Title>Vulnerable System</Title>
        <Value>{ vectorString ? vulnerable_system : 'N/A' }</Value>
      </Row>
      <Row>
        <Title>Subsequent System</Title>
        <Value>{ vectorString ? subsequent_system : 'N/A' }</Value>
      </Row>
      <Row>
        <Title>Exploitation</Title>
        <Value>{ vectorString ? exploitation : 'N/A' }</Value>
      </Row>
      <Row>
        <Title>Security Requirements</Title>
        <Value>{ vectorString ? security_requirements : 'N/A' }</Value>
      </Row>
    </Table>
  );
};

export default DetailsSection;
