import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { setField, updateTemplateSelected } from 'store/Settings/serviceDesk/actions';
import { CustomIcon } from '../../../Templates/components/TemplatesDropwdown';
import { Wrapper, List, Row, Fieldname, FieldInput, TrashWrapper, dropdownStyle } from './styled';
import { SectionTitle } from '../../styled';
import { selectOptionalFieldsTemplate, selectOptionalFieldsToAdd, selectTemplateSelected, selectTemplateToEdit } from 'store/Settings/serviceDesk/selectors';
import TextField from '../TextField';
import DropdownField from '../DropdownField';
import SearchField from '../SearchField';
import { Trash } from '../../../BasicSetupModal/components/Requester/styled';
import isEmpty from 'lodash/isEmpty';
import GroupedDropdown from '../GroupedDropdown';

const OptionalFields = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const options = useSelector(selectOptionalFieldsTemplate);
  const templateToEdit = useSelector(selectTemplateToEdit);
  const optionalFieldsToAdd = useSelector(selectOptionalFieldsToAdd);
  // const options = [{ name: 'asset', type: 'unknown' }, { name: 'request_type', type: 'dropdown' }, { name: 'site', type: 'search' }, { name: 'user', type: 'search' }, { name: 'usersagain', type: 'search' }];
  const optionsList = isEmpty(optionalFieldsToAdd)
    ? options.map((o) => ({ label: o.name, value: o }))
    : options.filter((opt) => !optionalFieldsToAdd.find((e) => e.name === opt.name)).map((o) => ({ label: o.name, value: o }));
  const [selectedOption, setSelectedOption] = useState('');
  const templateSelected = useSelector(selectTemplateSelected);

  const onSelectHandler = (item) => {
    setSelectedOption(item);
    if (isEmpty(optionalFieldsToAdd)) dispatch(setField('optionalFieldsToAdd', [item.value]));
    else dispatch(setField('optionalFieldsToAdd', [...optionalFieldsToAdd, item.value]));
  };

  const removeOptionalField = (e, field) => {
    e.preventDefault();
    e.stopPropagation();
    const template = { name: templateSelected.name, id: templateSelected.id };
    const fieldAdded = templateSelected?.fields?.find(item => Object.hasOwn(item, field.name));
    if (fieldAdded) {
      if (templateSelected?.fields?.length > 0) {
        const filteredFields = templateSelected.fields.filter(item => !Object.hasOwn(item, field.name));
        if (filteredFields) {
          const fieldToRemove = templateSelected.fields.find(el => Object.hasOwn(el, field.name));
          const updated = { [field.name]: { data: fieldToRemove[field.name]?.data?.name ? { name: '' } : { raw: '' }, type: fieldToRemove[field.name].type }, deleted: true };
          Object.assign(template, { fields: [...filteredFields, updated] });
        } else {
          const fieldToRemove = templateSelected.fields.find(el => Object.hasOwn(el, field.name));
          const updated = { [field.name]: { data: fieldToRemove[field.name]?.data?.name ? { name: '' } : { raw: '' }, type: fieldToRemove[field.name].type }, deleted: true };
          Object.assign(template, { fields: [updated] });
        }
        dispatch(updateTemplateSelected(template));
        const filteredValues = optionalFieldsToAdd?.filter((f) => f.name !== field.name);
        dispatch(setField('optionalFieldsToAdd', filteredValues));
      }
    } else {
      const filteredValues = optionalFieldsToAdd?.filter((f) => f.name !== field.name);
      dispatch(setField('optionalFieldsToAdd', filteredValues));
    }
  };

  return (
    <Wrapper>
      <SectionTitle>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.template.optional' }) }</SectionTitle>
      <Select
        isDisabled={ !templateToEdit }
        value={ selectedOption }
        onChange={ onSelectHandler }
        isSearchable={ false }
        options={ optionsList }
        controlShouldRenderValue={ false }
        placeholder={ '' }
        styles={ dropdownStyle }
        components={ { DropdownIndicator: CustomIcon } }
      />
      <List>
      { optionalFieldsToAdd && optionalFieldsToAdd.map((field) => (
        <Row key={ field.name }>
          <Fieldname title={ field.name }>{ field.name }</Fieldname>
          <FieldInput>
            { ((field.type === 'dropdown') && (field.name === 'category')) ? <GroupedDropdown fieldName={ field.name } /> : null }
            { field.type === 'unknown' ? <TextField fieldName={ field.name } /> : null }
            { ((field.type === 'dropdown') && (field.name !== 'category')) ? <DropdownField fieldName={ field.name } /> : null }
            { field.type === 'search' ? <SearchField fieldName={ field.name } /> : null }
          </FieldInput>
          <TrashWrapper onClick={ (e) => removeOptionalField(e, field) }>
            <Trash />
          </TrashWrapper>
        </Row>
      ))
      }
      </List>
    </Wrapper>

  );
};

export default OptionalFields;
