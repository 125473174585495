import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as TrashIcon } from 'Images/icon-action-bar-trash.svg';
import { ellipsisText } from 'Styles/styles';

export const Wrapper = styled.div`
  margin-top: 20px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 12.5px;
  color: ${colors.grey17};
  position: relative;
  margin-bottom: 12px;
  &:after {
    content: '*';
    color: ${colors.redPink};
    position: absolute;
    top: 1px;
    margin-left: 3px;
  }
`;
Title.displayName = 'Title';

export const dropdownStyle = {
  control: (provided, { isDisabled }) => ({
    display: 'flex',
    minHeight: '35px',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 #afafaf',
    backgroundColor: isDisabled ? '#edf2f7' : '#edf2f7',
    borderBottomWidth: '0px',
    color: 'white',
    paddingLeft: '10px',
    fontWeight: '700',
    textOverflow: 'ellipsis',
    borderLeft: '12px solid #53a9ff',
    cursor: 'pointer'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: (provided, { isDisabled }) => ({
    ...provided,
    fontSize: '12.5px',
    color: '#afb1bc',
    fontWeight: 400
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '13px',
    color: '#1c2243',
    fontWeight: 300
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#505965',
    width: '30px'
  }),
  option: (provided, { isFocused, data }) => ({
    ...provided,
    backgroundColor: isFocused ? '#edf2f7' : 'white',
    fontSize: '13.5px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: isFocused ? 'pointer' : 'default',
    paddingLeft: '22px',
    paddingRight: '22px',
    height: '36px',
    textTransform: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }),
  valueContainer: () => ({
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    marginRight: 'auto'
  }),
  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '190px'
  }),
  menuList: () => ({
    maxHeight: '152px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '10px',
    paddingBottom: '13px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: '#1c2243', width: 'fit-content', height: '19px', fontSize: '14px', fontWeight: 400, top: '8px', position: 'relative'
    };
  }
};

export const List = styled.div` 
  background-color: ${colors.white};
  box-shadow: 0 2px 50px 0 ${colors.iceBlue};
  overflow-y: auto;
  margin-top: 8px;
  max-height: 102px;
`;
List.displayName = 'List';

export const DataItem = styled.div`    
  cursor: pointer;
  padding: 0 14px;
  height: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
DataItem.displayName = 'DataItem';

export const DataItemWrapper = styled.div`
  display: flex;
  width: 100%;
`;
DataItemWrapper.displayName = 'DataItemWrapper';

export const Item = styled.p`
  text-align: left;
  font-size: 13.5px;
  line-height: 2.52;
  margin-right: auto;
  color: ${colors.darkBlueGrey};
  ${ellipsisText}
`;
Item.displayName = 'Item';

export const TrashWrapper = styled.div`
  margin-left: auto;
  padding-left: 20px;
`;
TrashWrapper.displayName = 'TrashWrapper';

export const Trash = styled(TrashIcon)` 
  width: 16px;
  height: 17px;
  align-self: flex-end;
  g > path { 
    fill: ${colors.blueGrey};
  }

`;
Trash.displayName = 'Trash';
