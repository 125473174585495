import * as Papa from 'papaparse';
import { RESET_STATE_TEMPLATE_CREATE_UPDATE, SET_TEMPLATE_CREATE_UPDATE } from 'Screens/Contextualization/KnowledgeBaseEditCreate/actions/Actions';
import api from 'services/api';
import axios, { CancelToken } from 'axios';
import { getWorkspaces } from 'Screens/Contextualization/Workspaces/actions/Actions';
import head from 'lodash/head';
import { CURRENT_API_VERSION } from 'services/api/connector';
import { selectQueryParam, selectRowsPerPage } from 'store/Filters/selectors';
import {
  resetFilters, setFilter, setOrderBy, setPageNumber
} from 'store/Filters/actions';
import { selectVulnsList, selectVulnsSelected } from 'store/Manage/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { KB_FILTERS } from 'store/Filters/constants';
import { newGetVulns } from 'store/Manage/filterActions';
import { lastSelected, selectShowDetail, selectTemplatesSelected, selectVulnTemplateDetailId, totalVulnTemplate, vulnTemplatesList } from 'store/KnowledgeBase/selectors';
import { redirect } from 'store/Router/actions';
import { selectCurrentAsset, selectSelected, selectVulns } from 'store/Contextualization/AssetDetail/selectors';
import { getVulns } from 'store/Contextualization/AssetDetail/actions';
import get from 'lodash/get';
import {
  selectVulnsSelected as selectVulnsSelectedOutsideWs,
  selectVulns as selectVulnsOutsideWs
} from 'store/ManageGeneral/selectors';
import { getVulnsGeneral } from 'store/ManageGeneral/actions';

export const GET_DATA_VULN_TEMPLATE_START = 'GET_DATA_VULN_TEMPLATE_START';
export const GET_DATA_VULN_TEMPLATE_SUCCESS = 'GET_DATA_VULN_TEMPLATE_SUCCESS';
export const GET_DATA_VULN_TEMPLATE_FAIL = 'GET_DATA_VULN_TEMPLATE_FAIL';
export const SHOW_VULN_TEMPLATE_MODAL_NEW = 'SHOW_VULN_TEMPLATE_MODAL_NEW';
export const SHOW_VULN_TEMPLATE_MODAL_EDIT = 'SHOW_VULN_TEMPLATE_MODAL_EDIT';
export const HIDE_VULN_TEMPLATE_MODAL = 'HIDE_VULN_TEMPLATE_MODAL';
export const IMPACT_SELECTED_VULN_TEMPLATE = 'IMPACT_SELECTED_VULN_TEMPLATE';
export const CREATE_UPDATE_VULN_TEMPLATE_START = 'CREATE_UPDATE_VULN_TEMPLATE_START';
export const CREATE_UPDATE_VULN_TEMPLATE_ERROR = 'CREATE_UPDATE_VULN_TEMPLATE_ERROR';
export const CREATE_UPDATE_VULN_TEMPLATE_SUCCESS = 'CREATE_UPDATE_VULN_TEMPLATE_SUCCESS';
export const HIDE_ERROR_VULN_TEMPLATE_NEW_UPDATE = 'HIDE_ERROR_VULN_TEMPLATE_NEW_UPDATE';
export const CONTEXT_VULN_TEMPLATE_SELECTED = 'CONTEXT_VULN_TEMPLATE_SELECTED';
export const VULN_TEMPLATE_SELECTED_ALL = 'VULN_TEMPLATE_SELECTED_ALL';
export const SHOW_MODAL_DELETE_CONFIRMATION_VULN_TEMPLATE = 'SHOW_MODAL_DELETE_CONFIRMATION_VULN_TEMPLATE';
export const HIDE_MODAL_DELETE_CONFIRMATION_VULN_TEMPLATE = 'HIDE_MODAL_DELETE_CONFIRMATION_VULN_TEMPLATE';
export const DELETE_VULN_TEMPLATE = 'DELETE_VULN_TEMPLATE';
export const HIDE_MODAL_DELETE_CONFIRMATION_VULN_TEMPLATE_SUCCEED = 'HIDE_MODAL_DELETE_CONFIRMATION_VULN_TEMPLATE_SUCCEED';
export const SHOW_MODAL_IMPORT_VULN_TEMPLATE = 'SHOW_MODAL_IMPORT_VULN_TEMPLATE';
export const HIDE_MODAL_IMPORT_VULN_TEMPLATE = 'HIDE_MODAL_IMPORT_VULN_TEMPLATE';
export const IMPORT_FILE_VULN_TEMPLATE = 'IMPORT_FILE_VULN_TEMPLATE';
export const IMPORT_FILE_VULN_TEMPLATE_SUCCESS = 'IMPORT_FILE_VULN_TEMPLATE_SUCCESS';
export const IMPORT_FILE_VULN_TEMPLATE_ERROR = 'IMPORT_FILE_VULN_TEMPLATE_ERROR';
export const SET_VISIBILITY_CREATE_MODAL_KB = 'SET_VISIBILITY_CREATE_MODAL_KB';
export const GET_DATA_TEMPLATES_SORT = 'GET_DATA_TEMPLATES_SORT';
export const GET_DATA_TEMPLATES_FILTER = 'GET_DATA_TEMPLATES_FILTER';
export const GET_VULNS_COUNT_SUCCESS = 'GET_VULNS_COUNT_SUCCESS';
export const GET_VULNS_COUNT_FAIL = 'GET_VULNS_COUNT_FAIL';
export const SET_KNOWLEDGE_BASE_PAGE = 'SET_KNOWLEDGE_BASE_PAGE';
// Template CSV uploading
export const SET_CSV_KB_FILE_INDEX_MANAGE = 'SET_CSV_KB_FILE_INDEX_MANAGE';
export const SET_UPLOAD_STATE_KB = 'SET_UPLOAD_STATE_KB';
export const UPLOAD_CSV_KB_UPDATE_PERCENT = 'UPLOAD_CSV_KB_UPDATE_PERCENT';
export const UPLOAD_CSV_KB_ERROR = 'UPLOAD_CSV_KB_ERROR';
export const CANCEL_REQUEST_UPLOAD_CSV_KB = 'CANCEL_REQUEST_UPLOAD_CSV_KB';
export const SET_NAME_INTO_FILE_ERROR_LIST_CSV_KB = 'SET_NAME_INTO_FILE_ERROR_LIST_CSV_KB';
export const RESET_FILE_STATE_KB = 'RESET_FILE_STATE_KB';
export const SET_FILES_TO_UPLOAD_KB = 'SET_FILES_TO_UPLOAD_KB';
export const VULN_TEMPLATE_UNSELECTED = 'VULN_TEMPLATE_UNSELECTED';
export const VULN_TEMPLATE_NEW_PIVOT = 'VULN_TEMPLATE_NEW_PIVOT';
export const CONTEXT_SELECT_ALL_VULN_TEMPLATE = 'CONTEXT_SELECT_ALL_VULN_TEMPLATE';
export const UNSELECT_ALL_VULN_TEMPLATE = 'UNSELECT_ALL_VULN_TEMPLATE';
export const VULN_TEMPLATE_SHOW_DETAIL = 'VULN_TEMPLATE_SHOW_DETAIL';
export const VULN_TEMPLATE_HIDE_DETAIL = 'VULN_TEMPLATE_HIDE_DETAIL';
export const VULN_TEMPLATE_UPDATE_SUCCESS = 'VULN_TEMPLATE_UPDATE_SUCCESS';
export const VULN_TEMPLATE_SET_DETAIL_TAB = 'VULN_TEMPLATE_SET_DETAIL_TAB';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const VULN_TEMPLATE_RESET_STATE = 'VULN_TEMPLATE_RESET_STATE';

export const resetState = () => (dispatch) => dispatch({ type: VULN_TEMPLATE_RESET_STATE });

// Summary: Validate csv headers
function equalAsSets (a, b) {
  if (a.length !== b.length) return false;
  let flag = true;

  a.forEach((elem) => {
    if (b.indexOf(elem) === -1) flag = false;
  });

  b.forEach((elem) => {
    if (a.indexOf(elem) === -1) flag = false;
  });

  return flag;
}

// Summary: Insert vuln templates in the csv file
function loadCSV (data, dispatch) {
  const datas = [];
  const url = `/_api/${CURRENT_API_VERSION}/vulnerability_template`;

  Papa.parse(data, {
    worker: true,
    header: true,
    skipEmptyLines: true,
    step (results) {
      if (results.data) {
        datas.push(results.data);
      }
    },
    complete () {
      const { length } = datas;
      let counter = 0;
      // TODO: Modify backend for use only one request, so add api call.
      datas.forEach((vulnt) => {
        const vulntCopy = vulnt;

        if (!vulntCopy.easeofresolution || vulntCopy.easeofresolution === '') vulntCopy.easeofresolution = null;

        fetch(url, {
          method: 'POST',
          body: JSON.stringify(vulntCopy),
          headers: {
            'Content-type': 'application/json'
          }
        }).then((result) => {
          if (result.ok) {
            counter += 1;
            if (length === counter) {
              dispatch({ type: IMPORT_FILE_VULN_TEMPLATE_SUCCESS });
            }
          } else {
            dispatch({ type: IMPORT_FILE_VULN_TEMPLATE_ERROR, errorMessage: `Some templates could not be imported. Error: ${result.statusText}` });
          }
        }).catch((error) => {
          dispatch({ type: IMPORT_FILE_VULN_TEMPLATE_ERROR, errorMessage: `Some templates could not be imported. Error: ${error}` });
        });
      });
    }
  });
}

const getTemplateRowsFailureCallback = (error) => ({
  type: GET_DATA_VULN_TEMPLATE_FAIL,
  data: error
});

const getWorkspaceRowsSuccedCallback = (data, total) => ({
  type: GET_DATA_VULN_TEMPLATE_SUCCESS,
  data,
  vulnTemplateCount: total
});

export function getVulnsTemplateCount () {
  return async (dispatch) => {
    try {
      const data = await api.manage.getTemplates();
      dispatch({ type: GET_VULNS_COUNT_SUCCESS, total: data.total_rows });
    } catch (e) {
      dispatch({ type: GET_VULNS_COUNT_FAIL, error: e.response || 'An error has occurred.' });
    }
  };
}

export function getData () {
  return async (dispatch, getState) => {
    dispatch({ type: GET_DATA_VULN_TEMPLATE_START });
    const state = getState();
    const params = selectQueryParam('knowledgeBase', state);
    try {
      const response = await api.knowledgeBase.fetchTemplates(params);
      dispatch(getVulnsTemplateCount());
      const data = response.rows.map((x) => x.doc);
      return dispatch(getWorkspaceRowsSuccedCallback(data, response.total_rows));
    } catch (e) {
      return dispatch(getTemplateRowsFailureCallback(e));
    }
  };
}

export function setVisibilityCreateModal (value) {
  return (dispatch) => {
    dispatch({ type: SET_VISIBILITY_CREATE_MODAL_KB, value });

    if (!value) dispatch({ type: RESET_STATE_TEMPLATE_CREATE_UPDATE, value });
  };
}

export function showVulnTemplateModalEdit () {
  return (dispatch, getState) => {
    const { templatesSelected } = getState().knowledgeBase;
    dispatch({ type: SET_TEMPLATE_CREATE_UPDATE, template: head(templatesSelected) });
    dispatch({ type: SET_VISIBILITY_CREATE_MODAL_KB, value: true });
  };
}

export function vulnTemplateSelected (selectionInfo) {
  return (dispatch) => {
    dispatch({
      type: CONTEXT_VULN_TEMPLATE_SELECTED, selectionInfo
    });
  };
}

export function redirectToVulnTemplate () {
  return (dispatch) => {
    dispatch(redirect('/knowledge_base'));
  };
}

export function redirectToVulnTemplateDetail (id) {
  return (dispatch) => {
    dispatch(redirect(`/knowledge_base/${id}`));
  };
}

export function showVulnTemplateDetail (vulnTemplate) {
  return (dispatch) => {
    dispatch({ type: VULN_TEMPLATE_SHOW_DETAIL, vulnTemplate });
  };
}

export function loadVulnTemplateDetail (id) {
  return async (dispatch) => {
    if (id > 0) {
      try {
        const vulnTemplate = await api.knowledgeBase.fetchTemplateById(id);
        dispatch(showVulnTemplateDetail(vulnTemplate));
      } catch (e) {
        dispatch(redirectToVulnTemplate());
      }
    }
  };
}

export function hideVulnTemplateDetail () {
  return (dispatch, getState) => {
    const showServiceDetail = selectShowDetail(getState());
    if (showServiceDetail) dispatch({ type: VULN_TEMPLATE_HIDE_DETAIL });
  };
}

// Summary: Show confirmation modal when user delete vuln template/s
export function showVulnTemplateModalDelete () {
  return (dispatch) => {
    dispatch({ type: SHOW_MODAL_DELETE_CONFIRMATION_VULN_TEMPLATE });
  };
}

// Summary: Hide confirmation modal when user delete vuln template/s
export function hideVulnTemplateModalDelete () {
  return (dispatch) => {
    dispatch({ type: HIDE_MODAL_DELETE_CONFIRMATION_VULN_TEMPLATE });
  };
}

// Summary: Delete vuln template from database
export function deleteVulnTemplateSelected () {
  return async (dispatch, getState) => {
    const templatesSelected = selectTemplatesSelected(getState());
    const templateIds = templatesSelected.map(temp => temp.id);
    dispatch({ type: DELETE_VULN_TEMPLATE });
    try {
      await api.knowledgeBase.deleteTemplates({ ids: templateIds });
      dispatch({ type: HIDE_MODAL_DELETE_CONFIRMATION_VULN_TEMPLATE_SUCCEED });
    } catch {
      dispatch({ type: HIDE_MODAL_DELETE_CONFIRMATION_VULN_TEMPLATE_SUCCEED });
    }
    dispatch(getData());
  };
}

// Summary: Show popup for csv import
export function showVulnTemplateModalImport () {
  return (dispatch) => {
    dispatch({ type: SHOW_MODAL_IMPORT_VULN_TEMPLATE });
  };
}

// Summary: Hide popup for csv import
export function hideVulnTemplateModalImport () {
  return (dispatch) => {
    dispatch({ type: HIDE_MODAL_IMPORT_VULN_TEMPLATE });
  };
}

// Summary: Init the csv import process
export function importFile (csv) {
  return (dispatch) => {
    dispatch({ type: IMPORT_FILE_VULN_TEMPLATE });

    const reader = new FileReader();
    reader.onload = function () {
      const text = reader.result;
      const expectedHeader = ['cwe', 'description', 'exploitation', 'name', 'references', 'resolution'];
      const actualHeader = text.split('\n').shift().trim().toLowerCase()
        .split(',');

      if (equalAsSets(expectedHeader, actualHeader)) {
        loadCSV(csv, dispatch);
      } else {
        document.body.style.cursor = 'default';
        dispatch({ type: IMPORT_FILE_VULN_TEMPLATE_ERROR, errorMessage: 'It appears your CSV has the wrong headers. Headers MUST be present.' });
      }
    };
    reader.readAsText(csv);
  };
}

export function sortData (sorting) {
  return (dispatch) => {
    dispatch(setOrderBy('knowledgeBase', sorting));
    dispatch(getData());
  };
}

export function setFilterTemplates (filter) {
  return (dispatch) => {
    dispatch({ type: GET_DATA_TEMPLATES_FILTER, filter });
    if (filter) dispatch(setFilter('knowledgeBase', KB_FILTERS.kbByName(`%${filter}%`)));
    else dispatch(resetFilters('knowledgeBase'));

    dispatch(getData());
  };
}

export function setFilesCount (files) {
  return (dispatch) => {
    dispatch({ type: SET_FILES_TO_UPLOAD_KB, files });
  };
}

export function setFileIndex (index) {
  return (dispatch) => {
    dispatch({ type: SET_CSV_KB_FILE_INDEX_MANAGE, index });
  };
}

export function setUploadState (state) {
  return (dispatch) => {
    dispatch({ type: SET_UPLOAD_STATE_KB, state });
  };
}

export function resetFileUploadState () {
  return (dispatch) => {
    dispatch({ type: RESET_FILE_STATE_KB });
  };
}

export function uploadFile (file) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_UPLOAD_STATE_KB, state: 'PROCESSING' });
      const response = await api.faraday.getSession();

      const cancelTokenSource = CancelToken.source();

      const fd = new FormData();
      fd.append('csrf_token', response.csrf_token);
      fd.append('file', file);

      await axios.post(`/_api/${CURRENT_API_VERSION}/vulnerability_template/bulk_create`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: async (progressEvent) => {
          dispatch({ type: UPLOAD_CSV_KB_UPDATE_PERCENT, progressEvent });
          if (Math.round((100 * progressEvent.loaded) / progressEvent.total) === 100) {
            const index = getState().knowledgeBase.fileIndex - 1;
            dispatch({ type: SET_CSV_KB_FILE_INDEX_MANAGE, index });
          }
        },
        cancelToken: cancelTokenSource.token
      });

      const currentIndex = getState().knowledgeBase.fileIndex;
      if (currentIndex < 0) {
        dispatch({ type: SET_UPLOAD_STATE_KB, state: 'FINISHED' });
        dispatch(getWorkspaces());
        dispatch(getData());
      } else {
        dispatch({ type: SET_UPLOAD_STATE_KB, state: 'PROCESSED' });
      }
    } catch (e) {
      if (!e.message || e.message !== 'cancel') {
        const currentIndex = getState().knowledgeBase.fileIndex;
        dispatch({ type: SET_NAME_INTO_FILE_ERROR_LIST_CSV_KB, name: file.name });
        const filesCount = getState().knowledgeBase.filesToUpload.length;
        if (filesCount === 1) {
          dispatch({ type: UPLOAD_CSV_KB_ERROR, error: e.response && e.response.data.message ? e.response.data.message : 'Couldn\'t upload your file. Please try again.' });
        }
        if (currentIndex < 0) {
          dispatch({ type: SET_UPLOAD_STATE_KB, state: 'FINISHED' });
          dispatch(getWorkspaces());
        } else {
          dispatch({ type: SET_UPLOAD_STATE_KB, state: 'PROCESSED' });
        }
      } else {
        dispatch({ type: CANCEL_REQUEST_UPLOAD_CSV_KB });
      }
    }
  };
}

export function setPage (page) {
  return async (dispatch) => {
    dispatch(setPageNumber('knowledgeBase', page));
    dispatch(getData());
  };
}

export const applyTemplate = (template, fields) => async (dispatch, getState) => {
  const vulnsSelected = selectVulnsSelected(getState());
  const workspaceSelected = selectCurrentWorkspace(getState());
  const vulnsList = selectVulnsList(getState());

  const data = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const field in fields) {
    if (fields[field]) {
      if (field === 'exploitation') data.severity = template[field];
      if (field === 'refs') {
        template[field] = template[field].map(name => { return { name, type: 'other' }; });
      }
      if (field === 'customfields') {
        data.custom_fields = template[field];
      }
      if (field === 'cvss') {
        if (template.cvss3.vector_string !== null) {
          data.cvss3 = template.cvss3;
        } else {
          data.cvss2 = template.cvss2;
        }
      }
      data[field] = template[field];
    }
  }

  const vulnPromises = vulnsSelected.map((vuln) => api.manage.updateVuln(workspaceSelected, { _id: vuln._id, ...data }));
  try {
    const res = await Promise.allSettled(vulnPromises);
    res.forEach(({ value }) => {
      const index = vulnsList.findIndex((x) => value._id === x.id || value._id === x._id);
      vulnsList[index] = value;
    });
    dispatch(newGetVulns());
  } catch (error) { }
};

export const applyTemplateFromAsset = (template, fields) => async (dispatch, getState) => {
  const vulnsSelected = selectSelected('vulns', getState());
  const workspaceSelected = selectCurrentWorkspace(getState());
  const vulnsList = selectVulns(getState());
  const asset = selectCurrentAsset(getState());
  const ip = get(asset, 'ip', '');
  const data = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const field in fields) {
    if (fields[field]) {
      if (field === 'exploitation') data.severity = template[field];
      if (field === 'refs') {
        template[field] = template[field].map(name => { return { name, type: 'other' }; });
      }
      if (field === 'customfields') {
        data.custom_fields = template[field];
      }
      if (field === 'cvss') {
        if (template.cvss3.vector_string !== null) {
          data.cvss3 = template.cvss3;
        } else {
          data.cvss2 = template.cvss2;
        }
      }
      data[field] = template[field];
    }
  }
  const vulnPromises = vulnsSelected.map((vuln) => api.manage.updateVuln(workspaceSelected, { _id: vuln._id, ...data }));
  try {
    const res = await Promise.allSettled(vulnPromises);
    res.forEach(({ value }) => {
      const index = vulnsList.findIndex((x) => value._id === x.id || value._id === x._id);
      vulnsList[index] = value;
    });
    dispatch(getVulns(ip));
  } catch (error) { }
};

export const applyTemplateFromOutsideWs = (template, fields) => async (dispatch, getState) => {
  const vulnsSelected = selectVulnsSelectedOutsideWs(getState());
  const vulnsList = selectVulnsOutsideWs(getState());

  const data = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const field in fields) {
    if (fields[field]) {
      if (field === 'exploitation') data.severity = template[field];
      if (field === 'refs') {
        template[field] = template[field].map(name => { return { name, type: 'other' }; });
      }
      if (field === 'customfields') {
        data.custom_fields = template[field];
      }
      if (field === 'cvss') {
        if (template.cvss3.vector_string !== null) {
          data.cvss3 = template.cvss3;
        } else {
          data.cvss2 = template.cvss2;
        }
      }
      data[field] = template[field];
    }
  }

  const vulnPromises = vulnsSelected.map((vuln) => api.manage.updateVuln(vuln.workspace_name, { _id: vuln._id, ...data }));
  try {
    const res = await Promise.allSettled(vulnPromises);
    res.forEach(({ value }) => {
      const index = vulnsList.findIndex((x) => value._id === x.id || value._id === x._id);
      vulnsList[index] = value;
    });
    dispatch(getVulnsGeneral());
  } catch (error) { }
};

const addDeleteController = (templates, templateList) => {
  const allTemplatesAreSelected = templates.every((testTemplate) => templateList.some((template) => template._id === testTemplate._id));

  if (allTemplatesAreSelected) return [VULN_TEMPLATE_UNSELECTED, templates];
  return [CONTEXT_VULN_TEMPLATE_SELECTED, templates];
};

const selectCalculator = (e, template, templateList, areTemplateSelected) => (dispatch, getState) => {
  const pivot = lastSelected(getState());
  const index = templateList.findIndex((el) => el._id === template._id);
  const templatesSelected = selectTemplatesSelected(getState());

  dispatch({ type: VULN_TEMPLATE_NEW_PIVOT, payload: index });
  if (e.shiftKey && pivot !== -1 && areTemplateSelected) {
    const start = Math.min(pivot, index);
    const end = Math.max(pivot, index) + 1;
    const templates = templateList.slice(start, end);
    const [type, payload] = addDeleteController(templates, templatesSelected);
    return dispatch({ type, payload });
  }
  const [type, payload] = addDeleteController([template], templatesSelected);
  return dispatch({ type, payload });
};

export const selectRow = (e, template) => (dispatch, getState) => {
  const allTemplate = vulnTemplatesList(getState());
  const templatesSelected = selectTemplatesSelected(getState());

  dispatch(selectCalculator(e, template, allTemplate, templatesSelected.length > 0));
};

export const unSelectAll = () => (dispatch) => dispatch({ type: UNSELECT_ALL_VULN_TEMPLATE });

export const selectAllTemplate = () => (dispatch, getState) => {
  const templatesList = vulnTemplatesList(getState());
  const templatesSelected = selectTemplatesSelected(getState());
  const templateCount = totalVulnTemplate(getState());
  const pageSize = selectRowsPerPage('knowledgeBase', getState());

  if (templatesSelected.length === templateCount || templatesSelected.length >= pageSize) return dispatch(unSelectAll());

  return dispatch({ type: CONTEXT_SELECT_ALL_VULN_TEMPLATE, templatesList });
};

export function updateVulnTemplate (vulnTemplateId, valueObj) {
  return async (dispatch, getState) => {
    const templatesList = vulnTemplatesList(getState());
    const templateDetailId = selectVulnTemplateDetailId(getState());
    const newTemplate = await api.knowledgeBase.updateTemplate(vulnTemplateId, valueObj);
    const index = templatesList.findIndex((x) => vulnTemplateId === x.id || vulnTemplateId === x._id);
    templatesList[index] = { ...newTemplate };
    dispatch({ type: VULN_TEMPLATE_UPDATE_SUCCESS, templatesList, newTemplate });
    if (templateDetailId && newTemplate._id === templateDetailId) dispatch(showVulnTemplateDetail(newTemplate));
  };
}

export function setVulnTemplateDetailTab (vulnTemplateDetailSelectedTab) {
  return (dispatch) => {
    dispatch({ type: VULN_TEMPLATE_SET_DETAIL_TAB, vulnTemplateDetailSelectedTab });
  };
}

export function setVulnTemplateCustomAttribute (vulnTemplate, fieldName, fieldValue) {
  return (dispatch) => {
    const { customfields, id } = vulnTemplate;
    const value = fieldValue === '(Empty)' ? '' : fieldValue;
    const newCustomAttribute = { ...customfields, [fieldName]: value };
    dispatch(updateVulnTemplate(id, { customfields: newCustomAttribute }));
  };
}

export const clearError = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
  };
};
