import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectIsFetchingTemplates } from 'store/Settings/serviceDesk/selectors';
import { Button, FetchIcon, Text, Spinner } from './styled';
import { getServiceDeskTemplates } from 'store/Settings/serviceDesk/actions';

const Content = () => {
  const intl = useIntl();
  const isFetchingTemplates = useSelector(selectIsFetchingTemplates);
  return (
    <>
      { isFetchingTemplates ? <Spinner /> : <FetchIcon /> }
      <Text>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.templates.button' }) }</Text>
    </>
  );
};

const FetchButton = () => {
  const isFetchingTemplates = useSelector(selectIsFetchingTemplates);
  const dispatch = useDispatch();
  const fetch = () => {
    dispatch(getServiceDeskTemplates());
  };
  return (
    <Button
      onClick={ isFetchingTemplates ? () => {} : fetch }
      id="service-desk-fetch-templates"
      children={ <Content /> }
    />
  );
};

export default FetchButton;
