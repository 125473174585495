import ModalWrapper from 'Common/Components/ModalWrapper';
import React from 'react';
import {
  ModalContent, Header, Title, Subtitle,
  DocLink, Buttons, CancelButton, SaveButton,
  Content
} from './styled';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'store/modals/actions';
import { MODAL_SERVICE_DESK_BASIC_SETUP } from 'store/modals/modals';
import Requester from './components/Requester';
import Severity from './components/Severity';
import Status from './components/Status';
import { saveBasicSetupSD } from 'store/Settings/serviceDesk/actions';
import { selectBasicSetupFilled } from 'store/Settings/serviceDesk/selectors';

const BasicSetupModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const docLink = '';
  const basicSetupFilled = useSelector(selectBasicSetupFilled);

  const close = () => dispatch(closeModal(MODAL_SERVICE_DESK_BASIC_SETUP));

  const save = () => {
    dispatch(saveBasicSetupSD());
    close();
  };

  return (
    <ModalWrapper>
      <ModalContent>
        <Header>
          <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.basicSetup.title' }) }</Title>
          <Buttons>
            <CancelButton onClick={ close }>Cancel</CancelButton>
            <SaveButton onClick={ save } disabled={ !basicSetupFilled }>Save</SaveButton>
          </Buttons>
        </Header>
        <Content>
          <Subtitle>
            { intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.basicSetup.subtitle' }) }
            <DocLink href={ docLink } target="_blank">{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceDesk.basicSetup.doc' }) }</DocLink>
          </Subtitle>
          <Requester />
          <Severity />
          <Status />
        </Content>
      </ModalContent>
    </ModalWrapper>
  );
};

export default BasicSetupModal;
