import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  margin-top: 15px;
`;
Wrapper.displayName = 'Wrapper';

export const Edit = styled.button`
  border: none;
  background-color: transparent;
  color: ${({ basicSetupFilled }) => basicSetupFilled ? '#0081ed' : colors.grey17};
  font-size: 14.5px;
  margin-left: 20px;
`;
Edit.displayName = 'Edit';
