import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import TABS from 'Screens/Contextualization/Host/components/HostVulnerabilities/VulnerabilityDetail/components/Tabs/components/TabSelector/tabs';
import { selectAdvancedFilter } from 'store/Filters/selectors';

export const selectHostDetail = (state) => get(state, 'assetDetail.host', null);
export const selectAssetDetailId = (state) => get(state, 'assetDetail.host._id', null);

export const selectVulns = (state) => get(state, 'assetDetail.vulns.data', []);
export const selectFields = (state) => get(state, 'assetDetail.vulns.fields', []);

export const selectShowHostDetail = (state) => {
  const show = get(state, 'assetDetail.host', null);
  return !!show;
};

export const selectIsFetching = (entity, state) => get(state, `assetDetail.${entity}.isFetching`, false);
export const selectServices = (state) => get(state, 'assetDetail.services.data', []);
export const selectServicesFields = (state) => get(state, 'assetDetail.services.fields', []);
export const selectSelected = (entity, state) => get(state, `assetDetail.${entity}.selected`, []);
export const selectCount = (entity, state) => get(state, `assetDetail.${entity}.count`, null);
export const selectPage = (entity, state) => get(state, `assetDetail.${entity}.page`, 0);
export const selectServiceRowsPerPage = (state) => get(state, 'assetDetail.services.rowsPerPage', 0);
export const selectLastSelected = (entity, state) => get(state, `assetDetail.${entity}.selectLastSelectedService`, -1);
export const selectError = (entity, state) => get(state, `assetDetail.${entity}.error`, false);
export const selectErrorMessage = (entity, state) => get(state, `assetDetail.${entity}.errorMessage`, '');
export const selectShowDetail = (entity, state) => get(state, `assetDetail.${entity}.showDetail`, false);
export const selectDetail = (entity, state) => get(state, `assetDetail.${entity}.detail`, null);
export const selectShowDeleteConfirmation = (entity, state) => get(state, `assetDetail.${entity}.showDeleteConfirmation`, false);
export const selectedForWorking = (entity, state) => get(state, `assetDetail.${entity}.selectedForWorking[0]`, []);
export const selectServiceId = (state) => get(state, 'assetDetail.services.detail._id', 0);
export const selectServiceOwned = (state) => get(state, 'assetDetail.services.detail.owned', false);
export const selectServiceDescription = (state) => get(state, 'assetDetail.services.detail.description', '');
export const selectVulnDetailId = (state) => get(state, 'assetDetail.vulns.detail._id', 0);

export const allServicesAreSelected = (state) => {
  const services = selectServices(state);
  const serviceList = selectSelected('services', state);
  return services.every((testService) => serviceList.some((service) => service._id === testService._id));
};
export const someServiceIsSelected = (state) => {
  return selectSelected('services', state).length > 0;
};

export const selectVulnSelectedTabId = (state) => get(state, 'assetDetail.vulns.selectedTab', 'general');
export const selectVulnSelectedTab = (state) => {
  const selectedTabId = selectVulnSelectedTabId(state);
  const defaultTab = TABS.find((tab) => tab.id === 'general');
  const selectedTab = TABS.find((tab) => tab.id === selectedTabId) || defaultTab;
  return selectedTab;
};

export const allVulnsAreSelected = (state) => {
  const vulns = selectVulns(state);
  const vulnsSelected = selectSelected('vulns', state);
  return vulns.every((testVuln) => vulnsSelected.some((vuln) => vuln._id === testVuln._id)) && vulnsSelected.length > 0;
};

export const someVulnIsSelected = (state) => {
  return selectSelected('vulns', state).length > 0;
};

export const selectHostDetailOs = (state) => get(state, 'assetDetail.host.os', '');
export const selectHostDetailMac = (state) => get(state, 'assetDetail.host.mac', '');
export const selectHostDetailDescription = (state) => get(state, 'assetDetail.host.description', '');
export const selectHostDetailHostnames = (state) => get(state, 'assetDetail.host.hostnames', null);
export const selectHostDetailOwned = (state) => get(state, 'assetDetail.host.owned', false);
export const selectHostDetailImportance = (state) => get(state, 'assetDetail.host.importance', 0);
export const selectHostDetailCreateDate = (state) => get(state, 'assetDetail.host.metadata.create_time', '');
export const selectHostDetailIp = (state) => get(state, 'assetDetail.host.ip', '');

export const selectToolsUsers = (state) => {
  const toolsData = get(state, 'hostDetail.tools.data', '');
  const users = toolsData.flatMap((tool) => tool.user);
  return users;
};

export const selectToolsParameters = (state) => {
  const toolsData = get(state, 'hostDetail.tools.data', '');
  const params = toolsData.flatMap((tool) => tool.params);
  return params;
};

export const selectToolsCommands = (state) => {
  const toolsData = get(state, 'hostDetail.tools.data', '');
  const commands = toolsData.flatMap((tool) => tool.command);
  return commands;
};

export const selectReadonly = (state) => {
  const readonly = get(state, 'faraday.readonly', false);
  return readonly;
};

export const selectVulnsQueryParam = (assetIp, state) => {
  const entityFilters = get(state, 'filters.vulnsAssets', null);

  const defaultFilter = {
    name: 'target',
    op: '==',
    val: assetIp
  };

  if (entityFilters) {
    const {
      page, rowsPerPage, order_by, filters
    } = entityFilters;
    const offset = (page - 1) * rowsPerPage;

    let parsedFilter = { offset, limit: rowsPerPage };
    const isOrdering = !isEmpty(order_by);
    const isFiltering = !isEmpty(filters);

    if (isOrdering) parsedFilter = Object.assign(parsedFilter, { order_by }, { filters: defaultFilter });
    if (isFiltering) {
      if (filters[0].and) {
        const filtersObj = filters[0].and.map((item) => item);
        const newFilters = [...filtersObj, defaultFilter];
        parsedFilter = Object.assign(parsedFilter, { filters: [{ and: newFilters }] });
      } else {
        parsedFilter = Object.assign(parsedFilter, { filters: [{ and: [defaultFilter, filters[0]] }] });
      }
    }
    return parsedFilter;
  }

  return [];
};

export const selectVulnsAdvancedFilterQueryParam = (assetIp, state) => {
  const entityFilters = get(state, 'filters.vulnsAssets', null);
  const {
    page, rowsPerPage, order_by
  } = entityFilters;
  const advancedFilter = selectAdvancedFilter(state, 'vulnsAssets');
  const defaultFilter = `{"name":"target","op":"==","val":"${assetIp}"}`;
  const orderBy = JSON.stringify(order_by);
  const offset = (page - 1) * rowsPerPage;

  let queryToParse;
  if (advancedFilter) queryToParse = `{"offset":${offset},"limit":${rowsPerPage},"order_by":${orderBy},"filters":[${defaultFilter}, ${advancedFilter}]}`;
  else queryToParse = `{"offset":${offset},"limit":${rowsPerPage},"order_by":${orderBy},"filters":[${defaultFilter}]}`;

  const parsedQuery = JSON.parse(queryToParse);
  return parsedQuery;
};

export const selectShowVulnsSidebarFilters = (state) => get(state, 'assetDetail.vulns.showFiltersSidebar', false);
export const selectCurrentAsset = (state) => get(state, 'assetDetail.hostForWorking', {});

export const selectModalBulkUpdateField = (state) => get(state, 'assetDetail.vulns.bulkUpdateField', '');
export const selectModalBulkUpdateValue = (state) => get(state, 'assetDetail.vulns.bulkUpdateValue', '');
export const selectBulkUpdateCustomAttribute = (state) => get(state, 'assetDetail.vulns.bulkUpdateCustomAttribute', null);

export const selectSelectAllVulns = (state) => get(state, 'assetDetail.vulns.selectAll', false);

export const selectUnselectedRowFromSelectAll = (state, entity) => get(state, `assetDetail.${entity}.unselectedRowFromSelectAll`, false);

export const selectShowContextMenu = (state) => get(state, 'assetDetail.vulns.showContextMenu', false);
export const selectContextMenuXPos = (state) => get(state, 'assetDetail.vulns.contextMenuXPos', 0);
export const selectContextMenuYPos = (state) => get(state, 'assetDetail.vulns.contextMenuYPos', 0);

export const selectSelectedMainVulns = (state) => {
  const selectedVulns = selectSelected('vulns', state);
  const mainVulns = selectedVulns.filter((v) => !isEmpty(v.duplicates_associated)).map((v) => ({ id: v.id, name: v.name }));
  const duplicatedVulns = selectedVulns.filter((v) => !isEmpty(v.duplicates_main));
  const relativeMains = duplicatedVulns.map((v) => ({ id: v.duplicates_main.id, name: v.duplicates_main.name }));

  const mainsInGroup = [...mainVulns, ...relativeMains];
  const set = new Set();
  const uniqueMains = mainsInGroup.filter((vuln) => (set.has(vuln.id) ? false : set.add(vuln.id)));

  return uniqueMains;
};

export const selectDetailDuplicates = (state) => get(state, 'assetDetail.vulns.detailDuplicates', []);

export const selectDetailTags = (entity, state) => get(state, `assetDetail.${entity}.detail.tags`, null);

export const selectVulnsTags = (state) => {
  const vulnsSelected = selectSelected('vulns', state);
  const vulnsTags = vulnsSelected.flatMap(vuln => vuln.tags);
  return vulnsTags;
};

export const selectServicesTags = (state) => {
  const servicesSelected = selectSelected('services', state);
  const servicesTags = servicesSelected.flatMap(service => service.tags);
  return servicesTags;
};

export const selectHostWorkspace = (state) => get(state, 'hostDetail.host.workspace_name', '');

export const selectCurrentReferences = (state) => {
  const detail = selectDetail('vulns', state);
  return sortBy(get(detail, 'refs', []));
};

export const selectIsFetchingVulnEnrichment = (state) => get(state, 'assetDetail.vulns.isFetchingVulnEnrichment', false);
export const selectServicesQueryParam = (assetIp, state) => {
  const entityFilters = get(state, 'filters.servicesAssets', null);

  const defaultFilter = {
    name: 'host',
    op: 'has',
    val: { name: 'ip', op: 'eq', val: assetIp }
  };

  if (entityFilters) {
    const {
      page, rowsPerPage, order_by, filters
    } = entityFilters;
    const offset = (page - 1) * rowsPerPage;

    let parsedFilter = { offset, limit: rowsPerPage };
    const isOrdering = !isEmpty(order_by);
    const isFiltering = !isEmpty(filters);

    if (isOrdering) parsedFilter = Object.assign(parsedFilter, { order_by }, { filters: defaultFilter });
    if (isFiltering) {
      if (filters[0].and) {
        const filtersObj = filters[0].and.map((item) => item);
        const newFilters = [...filtersObj, defaultFilter];
        parsedFilter = Object.assign(parsedFilter, { filters: [{ and: newFilters }] });
      } else {
        parsedFilter = Object.assign(parsedFilter, { filters: [{ and: [defaultFilter, filters[0]] }] });
      }
    }
    return parsedFilter;
  }

  return [];
};

export const selectServicesAdvancedFilterQueryParam = (assetIp, state) => {
  const entityFilters = get(state, 'filters.servicesAssets', null);
  const {
    page, rowsPerPage, order_by
  } = entityFilters;
  const advancedFilter = selectAdvancedFilter(state, 'servicesAssets');
  const defaultFilter = `{"name":"host","op":"has","val":{"name":"ip","op":"eq","val":${assetIp}}}`;
  const orderBy = JSON.stringify(order_by);
  const offset = (page - 1) * rowsPerPage;

  let queryToParse;
  if (advancedFilter) queryToParse = `{"offset":${offset},"limit":${rowsPerPage},"order_by":${orderBy},"filters":[${defaultFilter}, ${advancedFilter}]}`;
  else queryToParse = `{"offset":${offset},"limit":${rowsPerPage},"order_by":${orderBy},"filters":[${defaultFilter}]}`;

  const parsedQuery = JSON.parse(queryToParse);
  return parsedQuery;
};

export const selectVulnDetailWsName = (state) => get(state, 'assetDetail.vulns.detail.workspace_name', '');
